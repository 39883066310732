<div class="p-6">
    <ng-container *ngIf="data$ | async as data; else loading">
        <div class="text-2xl font-bold pb-6">{{ data.group.name }}</div>
        <ng-container *ngIf="tab$ | async as tab">
            <ion-segment [value]="tab" (ionChange)="tabSelected($event, tab)" mode="md">
                <ion-segment-button value="config">
                    <ion-label class="text-[11px] sm:text-sm">Configuration</ion-label>
                </ion-segment-button>
                <ion-segment-button value="members">
                    <ion-label class="text-[11px] sm:text-sm">Members ({{ data.members.length }})</ion-label>
                </ion-segment-button>
                <ion-segment-button value="payment">
                    <ion-label class="text-[11px] sm:text-sm">Payments</ion-label>
                </ion-segment-button>
                <ion-segment-button value="usage">
                    <ion-label class="text-[11px] sm:text-sm">Usage</ion-label>
                </ion-segment-button>
            </ion-segment>
            <div class="py-6">
                @if (tab === 'config') {
                    <cheaseed-json-form
                        [formData]="formData$ | async" 
                        (formChanged)="onFormChanged($event)"
                        (formSubmit)="onSubmit($event, data.group)"
                        (copyControl)="copyControl($event)"/>
                }
                @else if (tab === 'members') {
                    <div class="flex gap-2 items-center pb-8 justify-between mx-8">
                        @for (opt of memberActionOptions; track opt.label) {
                            <!-- <button mat-raised-button (click)="opt.action(data.group, data.members)">
                                {{ opt.label}}
                            </button> -->
                            <div class="self-center bg-slate-300 text-center text-black p-4 py-1 border cursor-pointer text-xs border-shadow-sm hover:bg-fidocs-dark-green hover:text-white"
                                    (click)="opt.action(data.group, data.members)">
                                    {{opt.label}}
                            </div>
                        }
                    </div>
                    <div class="flex items-center gap-y-2 mx-8 justify-center">
                        <div class="flex gap-0 items-center">
                            @for (opt of membersFilterOptions; track opt) {
                                <div class="self-center p-4 py-1 border cursor-pointer text-xs sm:text-sm"
                                    [class.bg-gray-200]="data.filter === opt" 
                                    (click)="this.membersFilter$.next(opt)">
                                    {{opt}}
                                </div>
                            }
                        </div>
                    </div>
                    <table class="w-full border mt-6">
                            <tr class="border">
                                <th class="p-2 text-left">User</th>
                                <th class="p-2 text-left">Status</th>
                                <th class="p-2 text-left">Actions</th>
                            </tr>
                        <tbody>
                            @for (member of data.filteredMembers; track member.userId) {
                                <tr class="border">
                                    <td class="p-2">{{member.userId}}</td>
                                    <td class="p-2">{{member.status}}</td>
                                    <td class="p-2">
                                        <a *ngIf="member.status === 'pending'" (click)="groupService.approveGroupMember(member.userId, data.group)">
                                            Approve
                                        </a>
                                        <a (click)="groupService.removeGroupMember(member, data.group)">
                                            Remove
                                        </a>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                }
                @else if (tab === 'payment') {    
                    <div class="flex gap-y-2 flex-row items-center justify-between">
                        <div class="text-lg p-3">Page Balance: {{ data.group.pageBalance || 0 }}</div>
                        <div>
                            <button
                                (click)="purchasePlan(data, data.group)"                                
                                class="bg-fidocs-blue font-bold text-white p-4 py-1 text-xs sm:text-sm rounded-lg cursor-pointer hover:text-gray-200 transition-colors duration-300 ease-in-out m-1">
                                Manage Plan
                            </button>
                        </div>
                    </div>                     
                    
                    <table class="w-full border mt-6">
                        <tr class="border text-center">
                            <th class="p-2">Invoice #</th>
                            <th class="p-2">Date</th>
                            <th class="p-2">Pages</th>
                            <th class="p-2">Total</th>
                            <th class="p-2">Status</th>
                        </tr>
                        <tbody>
                            @for (item of data.invoices; track item.id) {
                                <tr class="border">
                                    <td class="p-2 cursor-pointer underline">
                                        <a [href]="item.hosted_invoice_url" target="_blank">{{item.number}}</a>
                                    </td>
                                    <td class="p-2">{{item.createdAt | date:'short'}}</td>
                                    <td class="p-2">{{item.metadata.pages | number:'1.0-0'}}</td>
                                    <td class="p-2">{{(item.total / 100.0).toFixed(2) | currency:'USD'}}</td>
                                    <td class="p-2">{{item.status}}</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                }
                @else if (tab === 'usage') {
                    <table class="w-full border mt-6">
                            <tr class="border text-center">
                                <th class="p-2">User</th>
                                <th class="p-2">Documents</th>
                                <th class="p-2">Pages</th>
                            </tr>
                        <tbody>
                            @for (member of data.memberUsage; track member.userId) {
                                <tr class="border text-center">
                                    <td class="p-2">{{member.userId}}</td>
                                    <td class="p-2">{{member.numFiles}}</td>
                                    <td class="p-2">{{member.pageTotal}}</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                }
            </div>
        </ng-container>    
    </ng-container>
    <ng-template #loading>
        <cheaseed-spinner/>
    </ng-template>
</div>

