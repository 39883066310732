<div class="p-8">
    @if (data$ | async; as data) {
        <h1 class="text-2xl pt-4">
            @if (!data.group?.docId) {
                Group {{ groupId$ | async }} not found.
            }
            @else if (data.isAlreadyMember) {
                {{ data.user?.docId }} is already a member of group {{ data.group.name }}
            }
            @else {
                <div class="text-3xl">Join <b>{{ data.group.name }}</b> !</div>
            }
        </h1>
        <div class="pt-8">
            @if (!data.group?.docId || data.isAlreadyMember) {
                <div class="pt-6">
                    <div class="p-4 w-40 text-center bg-fidocs-green text-white cursor-pointer hover:bg-slate-500 rounded-full"
                        [routerLink]="['home']">
                        Continue
                    </div>
                </div>
            }
            @else {
                <div class="pt-6">
                    <div class="p-4 w-40 text-center bg-fidocs-green text-white cursor-pointer hover:bg-slate-500 rounded-full"
                        (click)="groupService.checkJoinGroup(data.user, data.group)">
                        Join Group
                    </div>
                </div>
            }
        </div>
    }
    @else {
        <cheaseed-spinner/>
    }
</div>