<div class="w-full flex flex-col">
    <button class="self-end mx-4 bg-fidocs-green hover:bg-slate-400 text-sm text-white font-bold py-2 px-4 rounded" (click)="exportCosts()">
        Export Costs
    </button>    
    <table class="my-8 w-full border-separate border-0 border-slate-400 text-sm">
        <thead>
            <tr class="px-2 text-center">
                <th>Time</th>
                <th>User</th>
                <th>Group</th>
                <th>File</th>
                <th>Pages</th>
                <th>Step</th>
                <th>Credits</th>
                <th>Cost</th>
                <th>Azure</th>
                <th>Elapsed</th>
            </tr>
        </thead>
        <tbody>
        @for (cost of costs$ | async; track cost.docId) {
            <tr class="even:bg-slate-100 odd:white text-center text-xs">
                <td class="p-1 w-40">{{cost.loggedAt | date: 'yyyy-MM-dd hh:mm:ssa' }}</td>
                <td class="p-1">{{cost.user}}</td>
                <td class="p-1">{{cost.groupDocId}}</td>
                <td class="p-1 w-60">{{cost.fileName}}</td>
                <td class="p-1">{{cost.numPages}}</td>
                <td class="p-1">{{cost.stepName}}</td>
                <td class="p-1">{{cost.credits}}</td>
                <td class="p-1">{{cost.cost?.toFixed(4)}}</td>
                <td class="p-1">{{cost.azureModelUsed ? cost.azureModelUsed + ": " + cost.azureTokensUsed + ', $' + cost.azureCost.toFixed(4) : '' }}</td>
                <td class="p-1">{{cost.elapsedMsec}}</td>
            </tr>
        }
        </tbody>
    </table>
</div>