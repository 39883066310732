import {
  BaseFlowTool,
  FlowFile,
  FlowStep,
  FlowTool,
} from '../fileflow.interface';
import { FileflowServiceInterface } from '../fileflow.interface';
import { getBlobFromBuffer } from '../utils';
import { Workbook } from 'exceljs';
import { DocuPandaTool } from './DocuPandaTool';
import { ExcelUtils } from '../excel-utils';
import { DocuPandaAsImageTool } from './DocuPandaAsImageTool';
import { DocuPandaRemoveWatermarkTool } from './DocuPandaRemoveWatermarkTool';
import { getDocupandaOutput } from '../docupanda-utils';

export class DocuPandaGenerateXlsTool extends BaseFlowTool {

    name = 'standard-xls-transformer'
    type = 'xls-transformer'
    description = 'Transform to Excel Document (standard)'
    precedents: FlowTool[] = [] // = [ inject(DocuPandaTool), inject(DocuPandaAsImageTool), inject(DocuPandaRemoveWatermarkTool) ]
    startTime = 0

    
    excelUtils: ExcelUtils
    constructor(flowService?: FileflowServiceInterface) {
      super()
      if(flowService)
        this.initialize(flowService)

      //initializeGlobalize()
    }
    
    initialize(flowService: FileflowServiceInterface) {
      this.flowService = flowService
      this.precedents.push(
        new DocuPandaTool(this.flowService),
        new DocuPandaAsImageTool(this.flowService),
        new DocuPandaRemoveWatermarkTool(this.flowService)
      )
      this.excelUtils = new ExcelUtils(flowService)

      return this
      //initializeGlobalize()
    }

    async execute(
        file: FlowFile,
        last: FlowStep | null) 
    {
      this.startTime = Date.now()
      
      // To get first step (ocr) file contents
      const ocr = await getDocupandaOutput(file, this.flowService)
      // Get the output from the last step
      const output = last ? await this.flowService.getFileContents(last.storageName) : null;
      if (!output)
        throw new Error(
          `No output found in last step ${last?.name} ${last?.storageName}`,
        );
      const pages = output.data.result.pages;
      this.flowService.log(`Pages in the analyzed document: ${pages.length}`);
      const wb = new Workbook()
      let table_id = 1
      for (let i = 0; i < pages?.length; i++) {
        const page = pages[i];
        const textRowNumbers: number[] = []
        this.flowService.debug('Converting page ', i + 1);
        const ws = wb.addWorksheet('Page' + ' ' + (i + 1));
        const sections = page.sections;
        let addedEmptyRow = false
        for (let j = 0; j < sections.length; j++) {
          this.flowService.debug('Converting section ', j);
          if (sections[j].type === 'text') {
            //this.flowService.debug(`TEXT: ${sections[j].text}`);
            //this.flowService.log('LENGTH: ', sections[j].text.length)
            //this.flowService.log('Found section type as text');
            // save the row number so that we can run merge cells on text rows later
            textRowNumbers.push(this.excelUtils.addTextElement(ws, sections[j].text, j))
            addedEmptyRow = false
          } 
          else if (sections[j].type === 'table') {
            //this.flowService.log('Found section type as table');
            //this.flowService.debug('TABLE Section:', sections[j].text);
            let tableRows = sections[j].tableList;
            //const start = getTableStartRow(tableRows); //check for blank rows; remove if present
            //tableRows = start > 0 ? tableRows.slice(start) : tableRows;
            //const corrected_rows = tableRows; // should be corrected by previous transform
            //this.flowService.log('corrected_rows[0]', corrected_rows[0]);
            const columnHeaderRow = tableRows[0]
            tableRows = tableRows.slice(1); // remove the column header row
            
            //this.flowService.debug('input rows to excel: ', tableRows);  
            //this.flowService.debug('input column headers to excel: ', columnHeaderRow); 
            if(!addedEmptyRow)
              ws.addRow(table_id, ' ')           
            this.excelUtils.addTable(ws, tableRows, columnHeaderRow, table_id, {pages: ocr.data.result.pages, pageNumber: i, sectionNumber: j})
            ws.addRow(table_id, ' ')
            addedEmptyRow = true
            table_id++
          }
        }
        this.excelUtils.autoFitColumns(ws)
        this.excelUtils.mergeTextCells(ws, textRowNumbers)
      }
      // Upload the workbook to storage
      const blob = getBlobFromBuffer(await wb.xlsx.writeBuffer());
      await this.flowService.uploadBlob(this, file, blob, '.xlsx');
    }
  
    getContentDisposition(fileName: string) {
      return  'attachment; filename*=utf-8\'\'' + fileName
    }
}
