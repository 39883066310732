import { ChangeDetectionStrategy, Component, Inject, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JsonFormComponent, LabelledSpinnerComponent } from '@cheaseed/shared/ui';
import { IonLabel, IonSegment, IonSegmentButton } from '@ionic/angular/standalone';
import { BehaviorSubject, combineLatestWith, filter, map } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { PortalUtilityService } from '@cheaseed/portal/util';
import { filterNil } from 'ngxtension/filter-nil';
import { 
  MAX_GROUP_NAME_LENGTH, 
  MIN_GROUP_NAME_LENGTH, 
  Group, 
  GroupMember,
  PipelineStepLog,
} from '@fidoc/shared';
import { DefaultsService, Environment } from '@fidoc/util';
import { JsonFormData } from '@cheaseed/node-utils'
 
import { MatMenuModule } from '@angular/material/menu';
import { GroupService } from '../groups/group.service';
import { MatButtonModule } from '@angular/material/button';

enum MemberFilterOption {
  All = 'All',
  Active = 'Active',
  Pending = 'Pending',
  PreApproved = 'Pre-approved',
  Invited = 'Invited' // Implies pre-approved
}

export interface MemberUsage {
    userId: string
    numFiles: number
    pageTotal: number
}

@Component({
  selector: 'lib-edit-group',
  standalone: true,
  imports: [
    CommonModule,
    JsonFormComponent,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    MatMenuModule,
    MatButtonModule,
    LabelledSpinnerComponent
],
  templateUrl: './edit-group.component.html',
  styles: `
  a {
    padding-right: 10px;
    color: blue;
    cursor: pointer;
  }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditGroupComponent {

  private route = inject(ActivatedRoute)
  private utilityService = inject(PortalUtilityService)
  groupService = inject(GroupService)
  private defaultsService = inject(DefaultsService)
  tab$ = new BehaviorSubject('config')
  membersFilter$ = new BehaviorSubject(MemberFilterOption.All)
  membersFilterOptions = Object.values(MemberFilterOption)
  configChanged$ = new BehaviorSubject(false)
  formData$ = new BehaviorSubject<JsonFormData>({} as JsonFormData)

  data$ = this.groupService.selectedGroup$
    .pipe(
      filterNil(),
      combineLatestWith(
        this.membersFilter$,
        this.groupService.selectedGroupMembers$,
        this.groupService.selectedGroupCosts$,
        this.groupService.selectedGroupInvoices$,
      ),
      map(([group, filter, members, costs, invoices]) => {
        // Prepare Forms
        this.formData$.next(this.prepareGroupFormData(group, this.configFormData))
        // Return view data
        const filteredMembers = members.filter(m => m.status === filter.toLowerCase() || filter === 'All')
        // For each member, compute the number of file and total pages processed from the costs
        const memberUsage = members.map(member => {
            const memberCosts = costs.filter((cost: PipelineStepLog) => cost.user === member.userId)
            const numFiles = [ ...new Set(memberCosts.map((cost: PipelineStepLog) => cost.fileName)) ].length
            const pageTotal = memberCosts.reduce((acc, cost:PipelineStepLog) => acc + (cost?.numPages || 0), 0)
            return {
                userId: member.userId,
                numFiles,
                pageTotal
            } as MemberUsage
        }).filter(usage => usage.numFiles > 0)
        return {
          group, 
          filter,
          filteredMembers,
          members,
          memberUsage,
          invoices: invoices.map((invoice: any) => ({ ...invoice, createdAt: new Date(invoice.created * 1000) }))
        }})
    )

  memberActionOptions = [
    { label: 'Add Pre-approved Members', action: (group: Group, members: GroupMember[]) => this.addPreApprovedMembers(group, members) },
    { label: 'Invite Members', action: (group: Group, members: GroupMember[]) => this.invite(group, members) },
    { label: 'Remove All Members', action: (group: Group, members: GroupMember[]) => this.removeAllMembers(group, members) }
  ];

  configFormData: JsonFormData = {
    controls: [
      {
        name: 'name',
        label: 'Group Name',
        type: 'text',
        required: true,
        errorText: "Must be at least 10 characters and at most 40 characters",
        validators: {
          required: true,
          minLength: MIN_GROUP_NAME_LENGTH,
          maxLength: MAX_GROUP_NAME_LENGTH
        },
      },
      {
        name: 'description',
        label: 'Group Description',
        type: 'textarea',
        helperText: "Describe the group in a few words",
      },
      {
        name: 'ownerUserId',
        label: 'Group Owner',
        type: 'email',
        helperText: "Email of the group owner",
      },
      {
        name: 'invitationLink',
        label: 'Invitation Link',
        type: 'url',
        readonly: true
      },
      {
        name: 'balance',
        label: 'Page Balance',
        type: 'static',        
        readonly: true
      },
      {
        name: 'approvedAt',
        label: 'Approved At',
        type: 'date',
        readonly: true
      },
      {
        name: 'updatedAt',
        label: 'Last Updated',
        type: 'date',
        readonly: true
      },
    ],
  }

  constructor(@Inject('environment') private environment: Environment) {

    // this.utilityService.backButtonAvailable$.next(true)

    // this.utilityService.backButtonClicked$
    //   .pipe(
    //     filter(clicked => !!clicked),
    //     takeUntilDestroyed())
    //   .subscribe(() => {
    //     this.groupService.selectedGroupDocId$.next(null)
    //     this.utilityService.goBack('/groups')
    //     this.utilityService.backButtonClicked$.next(false)
    //   })

    this.route.params
      .pipe(
        map(params => params['groupId'] as string),
        filter(groupId => !!groupId))
      .subscribe(groupId => this.groupService.selectedGroupDocId$.next(groupId as string))
  }

  async presentToast(message: string) {
    await this.utilityService.presentToast(message, 
      { 
        duration: 2000, 
        position: 'bottom'
      })
  }

  // Handle prep of the form
  prepareGroupFormData(group: Group, templateControls: JsonFormData) {
    const controls = templateControls.controls.map(control => ({
      ...control, 
      value: group[control.name] 
    }))
    return { controls } as JsonFormData
  }

  // Handle submit of the form
  async onSubmit(values: any, group: Group) {
    const { name, description, ownerUserId } = values

    // Reset group's invitation link if name has changed to force regeneration with new group name
    if (name !== group.name)
      group.invitationLink = undefined

    // Check that ownerUserId does not already own a group; if so, reject save #461
    if (!!ownerUserId && await this.groupService.doesGroupOwnerOwnAnotherGroup(ownerUserId, group)) {
        await this.utilityService.notify({ message: `Owner ${ownerUserId} already owns a group` })
        group.ownerUserId = ''
        this.formData$.next(this.prepareGroupFormData(group, this.configFormData))
        return
    }

    // If pricingPlan is empty and affiliate selected, set to affiliate default
    // Reset approvedAt data when pricing plan changes
    const approvedAt = (group.approvedAt || new Date())
    await this.groupService.putGroup(group, { 
      name, 
      description, 
      approvedAt,
      ownerUserId
     })
    await this.presentToast(`Saved ${name}`)
  }

  tabSelected(ev: any, currentTab: string) {
    const value = ev.detail.value    
    if (currentTab !== value.toString()) {
      // console.log('Tab selected', ev, currentTab)
      const changed = this.configChanged$.value
      if (changed) {
        this.utilityService.confirm({
          header: 'Unsaved Changes',
          message: 'You have unsaved changes. Are you sure you want to switch tabs and lose your changes?',
          yesLabel: 'Yes',
          noLabel: 'No',
          confirm: async () => {
            this.configChanged$.next(false)
            this.tab$.next(value.toString())
          },
          cancel: () => { 
            this.tab$.next(currentTab)
            ev.target.value = currentTab
          }
        })
      }
      else {
        this.tab$.next(value.toString())
      }
    }
  }

  onFormChanged(value: boolean) {
    // console.log('Form changed', value)
    this.configChanged$.next(value)
  }

  async onAffiliateSubmit(values: any, group: Group) {
    //console.log('Form submitted', values, group)
    const { affiliateTerms } = values
    await this.groupService.putGroup(group, { affiliateTerms })
    await this.presentToast(`Saved ${group.name}`)
  }

  copyControl(control: any) {
    // console.log('Copied to clipboard', control)
    this.presentToast(`Copied ${control.label} to clipboard`)
  }

  async shareLink(group: Group) { 
    const shareData = {
      title: "fidocs group invitation link",
      text: "Join my group on fidocs",
      url: group.invitationLink
    }
    try {
      await navigator.share(shareData)
    }
    catch (err:any) {
      if (err.name === 'AbortError' || err.name === 'InvalidStateError') return
      console.error('Error sharing', err)
      await this.utilityService.notify({ message: 'Unable to share on this browser.' })
    }
  }

  async clickPaymentLink(link: any, group: Group) {
    const url = `${this.environment.production ? link.prodUrl : link.devUrl}?client_reference_id=${group.docId}`
    window.open(url, '_blank')
  }

  async addPreApprovedMembers(group: Group, members: GroupMember[]) {
    // TODO: Add invite checkbox
    await this.utilityService.prompt({
      header: 'Add Pre-approved Members',
      message: 'Enter the email addresses of pre-approved members, separated by commas or newlines. Pre-approved members are automatically approved to join the group, but you must notify them through some other means.',
      inputType: 'textarea',
      okText: 'Add',
      confirm: async (data:any) => {        
        await this.groupService.addPreApprovedMembers(group, data.value, members)
      }
    })
  }

  async invite(group: Group, members: GroupMember[]) {
    await this.utilityService.prompt({ 
      header: 'Invite Members', 
      message: 'Enter the email addresses of group members to invite, separated by commas or newlines. An email will be sent to each invited member. Invitees are automatically approved to join the group.',
      inputType: 'textarea', 
      okText: 'Invite', 
      confirm: (data:any) => {
        this.groupService.inviteMembers(group, data.value, members)
      }
    }) 
  }

  async removeAllMembers(group: Group, members: GroupMember[]) {
    await this.utilityService.confirm({
      header: 'Remove All Members',
      message: 'Are you sure you want to remove all members from the group?',
      confirm: async () => {
        this.groupService.removeAllGroupMembers(group, members)
      }
    })
  }   

  async purchasePlan(data, group: Group) {
    await this.utilityService.notify({ message: 'Purchasing plans is not yet available.' })
    //const inputs = links.map(link => ({ value: link.name, label: link.title, type: 'radio' } as AlertInput))
    /*const alert = await this.alertController.create({
      message: 'Select a plan to purchase',
      inputs,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        }, 
        {
          text: 'OK',
          handler: async (selected:any) => {
            const link = links.find(link => link.name === selected)
            this.clickPaymentLink(link, group)
            this.notifyAboutPurchase(link, group)
          }
        }
      ]
    })
    await alert.present()
    */
  }

  async notifyAboutPurchase(link: any, group: Group) {
    const message = this.defaultsService.getDefault('portal.prepaid.purchaseDelay.message') ||
      `It may take up to 5 minutes after completing a purchase to update your group's prepaid balance.`
    await this.utilityService.notify({ message })
  }

}