import { ChangeDetectionStrategy, Component, computed, effect, Inject, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { StripeService, UserService } from '@fidoc/util';
import { IonContent, IonModal } from '@ionic/angular/standalone';
import { FormsModule } from '@angular/forms';
import { PipelineSelectorComponent, ToolsService } from '@fidoc/fileflow';
import { getPerUnitPrice, getStripe, TRIAL_PAGE_BALANCE, UserRecord } from '@fidoc/shared';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PortalUtilityService } from '@cheaseed/portal/util';
import { GroupService } from '@fidoc/groups';

@Component({
  selector: 'lib-profile',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    IonModal,
    IonContent,
    PipelineSelectorComponent
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent {

  toolsService = inject(ToolsService)
  userService = inject(UserService)
  stripeService = inject(StripeService)
  utilityService = inject(PortalUtilityService)
  groupService = inject(GroupService)
  
  editDefaultPipeline = signal(false)
  starting_trial_page_balance = TRIAL_PAGE_BALANCE
  subscription = signal<any>(null)
  unit_price = signal<number>(0)
  constructor(@Inject('environment') private environment: any) {

    effect(async () => {
      const user = this.userService.user();
      const info = user?.subscriptionInfo;
      const sub = info 
        ? await this.stripeService.getSubscription(info.subscriptionId as string) 
        : null;
      this.subscription.set(sub)
      if(info) {
        const price = await this.getUnitPrice(info.productId as string)
        if(price)
          this.unit_price.set(price/100)
      }
    }, { allowSignalWrites: true })

  }

  statuses:any = {
    'active': 'Active',
    'canceled': 'Cancelled',
    'incomplete': 'Incomplete',
    'incomplete_expired': 'Incomplete Expired',
    'trialing': 'Trial Period',
    'past_due': 'Past Due',
    'unpaid': 'Unpaid'
  }

  getStatusDescription(status: string | undefined) {
    return this.statuses[status as string]
  }
  
  async getUnitPrice(productId: string) {
    const price = await getPerUnitPrice(productId, getStripe(this.environment.production ? "prod": "dev"))
    console.log('price', price)
    return price.unit_amount
  }
  describePipeline(pipeline?: string) {
    const result = this.toolsService.getPipeline(pipeline as string)
    return result ? result.description : ''
  }

  setDefaultPipeline(selectedPipeline: string | undefined) {    
    this.userService.updateUser(this.userService.user() as UserRecord, { defaultPipeline: selectedPipeline })
  }

  async editUserName() {
    this.utilityService.prompt({
      header: 'Enter user name',
      inputType: 'text',
      confirm: async (name: any) => {
        await this.userService.updateUser(this.userService.user() as UserRecord, { name: name.value })
      }
    })
  }

  async confirmCancel(user: UserRecord) {
    await this.utilityService.confirm({ 
      header: 'Cancel Subscription',
      message: 'Are you sure you want to cancel your subscription?',
      confirm: async () => {
        await this.userService.cancelSubscription(user)
      }
    })
  }

}