<form [formGroup]="myForm" (ngSubmit)="onSubmit()" class="grid grid-cols-[25%_75%] gap-2 gap-y-6 items-center">
  @for(control of formData.controls; track control.name) {
    <ion-label class="self-center">
      {{ control.label }}
      @if (control.infoText) {
        <ion-icon id="info-trigger" name="information-circle-outline" size="small" class="text-gray-400" aria-hidden="true" (click)="this.infoText$.next(control.infoText)"></ion-icon>
        <ion-popover trigger="info-trigger" triggerAction="click">
          <ng-template>
            <ion-content class="ion-padding"><div class="p-3 bg-slate-50">{{ infoText$ | async}}</div></ion-content>
          </ng-template>
        </ion-popover>
        <!-- <ion-text class="text-sm text-gray-400">{{ control.infoText }}</ion-text> -->
      }
    </ion-label>
    @if (!control.visible || control.visible(myForm)) {
      @if (['text', 'password', 'email', 'number', 'search', 'tel', 'url'].includes(control.type)) {
        <ion-input
          [formControlName]="control.name"
          [readonly]="!!control.readonly"
          [placeholder]="control.placeholder" 
          [helperText]="control.helperText"
          [errorText]="control.errorText"
          [type]="control.type"
          [value]="control.value">
          <ion-icon *ngIf="control.readonly" slot="start" name="lock-closed" aria-hidden="true"></ion-icon>
          <ion-button fill="clear" slot="end" aria-label="copy" (click)="copyToClipboard(control)">
            <ion-icon slot="icon-only" size="small" name="copy-outline" aria-hidden="true"></ion-icon>
          </ion-button>
        </ion-input>
      }
      @if (control.type === 'textarea') {
        <ion-textarea
          [className]="control.cssClass"
          [formControlName]="control.name"
          [readonly]="!!control.readonly"
          [placeholder]="control.placeholder" 
          [autoGrow]="true"
          [value]="control.value">
          <ion-button fill="clear" slot="end" aria-label="copy" (click)="copyToClipboard(control)">
            <ion-icon slot="icon-only" size="small" name="copy-outline" aria-hidden="true"></ion-icon>
          </ion-button>
        </ion-textarea>
      }
      @if (control.type === 'select') {
        <select [formControlName]="control.name">
          <option *ngFor="let option of control.choices" [value]="option.value">{{option.label || option.value }}</option>
        </select>
      }
      @if (control.type === 'radio') {
        <fieldset>    
          <div *ngFor="let option of control.choices">
            <input class="m-2" type="radio" 
              [id]="option.value" 
              [name]="control.name" 
              [formControlName]="control.name" 
              [value]="option.value"/>
            <label class="text-sm sm:text-base" [for]="option.value">{{ option.label || option.value }}</label>
          </div>
        </fieldset>        
      }
      @if (control.type === 'buttons') {
        <div>
          @for (button of control.choices; track button.label) {
            <button 
              class="bg-chea-purple font-bold text-white p-4 py-1 text-xs sm:text-sm rounded-lg cursor-pointer hover:text-gray-200 transition-colors duration-300 ease-in-out m-1"
              (click)="control?.click(button)">
              {{ button.label }}
            </button>
          }
        </div>
      }
      @if (control.type === 'checkbox') {
        <ion-checkbox
          [formControlName]="control.name"
          [checked]="control.value">
        </ion-checkbox>
      }
      @if (control.type === 'date') {
        @if (control.readonly) {
          {{ control.value || '-' }}
        }
        @else {
          <ion-datetime-button id="date" datetime="datetime" style="width: fit-content"></ion-datetime-button>
          <ion-popover trigger="date" [keepContentsMounted]="true">
            <ng-template>
                <ion-datetime
                  id="datetime" 
                  presentation="date"
                  max="2026-12-31T23:59:59"
                  [preferWheel]="false"
                  [showDefaultButtons]="false"
                  [formControlName]="control.name"                 
                  locale="en-US">
                </ion-datetime>
            </ng-template>
          </ion-popover>
        }
      }
      @if (control.type === 'currency') {
        @if (control.readonly) {
          {{ (control.value || 0.0) | currency: 'USD' }}
        }
      }
      @if (control.type === 'static') {
          <b>{{ control.value }}</b>
      }
      @if (control.type === 'toggle') {
        <ion-toggle
          [formControlName]="control.name"
          [checked]="control.value">
        </ion-toggle>
      }
      @if (control.type === 'range') {
        <ion-range        
          [pin]="true"
          [min]="control.options?.min"
          [max]="control.options?.max"
          [formControlName]="control.name">
          <ion-icon size="small" slot="start" [name]="control.options?.icon"/>
          <ion-icon slot="end" [name]="control.options?.icon"/>
        </ion-range>
      }
    }
  }
  <div class="col-span-2 flex items-center justify-center pt-6">
    <button 
      class="bg-chea-purple text-lg font-bold text-white w-60 rounded-lg h-10 cursor-pointer hover:text-black transition-colors duration-300 ease-in-out"
      [ngClass]="{ submitDisabled: !myForm.dirty && !formData.submitAlwaysEnabled }"
      type="submit"
      [disabled]="!myForm.dirty && !formData.submitAlwaysEnabled">
     Submit
    </button>
  </div>
</form>