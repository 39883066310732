import { delay } from "./utils"

export const LLAMACLOUD_KEY = 'llx-LGyQD72hC53tDY8ckBE4sVHpusvRiprKHq0QshhfGn3PPu7I'
export const LLAMAINDEX_BASE_ENDPOINT = 'https://api.cloud.llamaindex.ai/api/parsing'
export const LLAMAINDEX_CREDIT_COST = 0.003;
export const LLAMAINDEX_CREDIT_OVERAGE_COST = 0.003;

function getFetchHeaders() {
  return {
      "accept": 'application/json',
      "Authorization": `Bearer ${LLAMACLOUD_KEY}`
  }
}

export async function llamaparsePost(blob: Blob, filename: string, webhookUrl?: string): Promise<any> {
  const formData = new FormData()
  formData.append('file', blob, filename)
  formData.append('skip_diagonal_text', 'true')
  formData.append('webhook_url', webhookUrl)
  const response = await post(`${LLAMAINDEX_BASE_ENDPOINT}/upload`, formData)
  console.log('llamaindex post response', response)
  return response
}

export async function llamaparsePoll(jobId: string, interval: number): Promise<any> {
  const response = await poll(`${LLAMAINDEX_BASE_ENDPOINT}/job/${jobId}`, interval)
  console.log('llamaindex poll response', response)
  // Get text and markdown results
  const textResponse = await getLlamaIndexResult(jobId, 'text');
  const markdownResponse = await getLlamaIndexResult(jobId, 'markdown');
  const jsonResponse = await getLlamaIndexResult(jobId, 'json');
  const job_metadata = markdownResponse.job_metadata;
  return { ...response, job_metadata, text: textResponse.text, markdown: markdownResponse.markdown, json: jsonResponse.pages }
}

export async function getLlamaIndexResult(jobId: string, type: string): Promise<any> {
  const response = await get(`${LLAMAINDEX_BASE_ENDPOINT}/job/${jobId}/result/${type}`)
  console.log(`llamaindex ${type} result`, response)
  return response
}

async function get(url: string) {
  const   method = 'GET',
          headers = getFetchHeaders(),
          options = { method, headers }
  console.log(`${method} ${url}`)
  const res = await fetch(url, options)
  const json = await res.json()
  return json
}

async function del(url: string) {
  const   method = 'DELETE',
          headers = getFetchHeaders(),
          options = { method, headers }
  console.log(`${method} ${url}`)
  const res = await fetch(url, options)
  const json = await res.json()
  return json
}

async function post(url: string, body: any, debug = false) {
  const   method = 'POST',
          headers = getFetchHeaders(),
          options = { method, headers, body}
  console.log(`${method} ${url}`)
  const res = await fetch(url, options)
  const json = await res.json()
  return json
}

async function poll(url: string, interval = 2000) {
  let data = await get(url)
  while (data.status && data.status === 'PENDING') {
    await delay(interval)
    data = await get(url)
  }
  if (data.status === 'ERROR') {
    throw new Error(`Error detected while polling ${url}: ${JSON.stringify(data)}`)
  }
  return data
}