import { AzureOpenAI } from 'openai';
import { OpenAIConfig, OpenAIInterface, OpenAIResult } from './openai-interface';
import { LoggerInterface } from './logger-interface';
import { MAX_OPENAI_RETRIES, OPENAI_40_TURBO_MODEL_NAME } from './constants';
import { Prompt } from './prompts-base';

export class OpenAICompletions implements OpenAIInterface {
  openai: AzureOpenAI
  logger: LoggerInterface
  constructor(config: OpenAIConfig) {
    const server = getAzureOpenAIServer(config.env)
    this.openai = // config.isAzure ?
      new AzureOpenAI({
        endpoint: server.endpoint,
        apiKey: server.key,
        maxRetries: MAX_OPENAI_RETRIES, 
        dangerouslyAllowBrowser: true,
        apiVersion: "2024-06-01",
        deployment: server.defaultModel
        })
      // :
      // new OpenAIClient(
      //   new OpenAIKeyCredential(OPENAI_API_KEY),
      //   {
      //     retryOptions: { maxRetries: MAX_OPENAI_RETRIES, retryDelayInMs: 100 }
      //   })
    this.logger = config.logger || console
    this.logger.log(`Using Azure OpenAI server: ${JSON.stringify(server)}`)
  }

  async logChatQuery(
    config: OpenAIConfig,
    prompt: string,
    prompts: Prompt[],
    expectsJson: boolean) {

    // Handle system prompts
    const model = config.model
    const msgs = prompts.map(p => {
      const req = { role: p.system ? 'system' : 'user', content: p.prompt }
      const resp = p.system ? null : { role: p.role, content: this.generateAsString(p.response) }
      return resp ? [req, resp] : [req]
    }).flat()
    const chatMsgs = [...msgs, { role: 'user', content: prompt }]
    this.logger.log(JSON.stringify({ messageLength: chatMsgs.length, model }))
    const startmsecs = Date.now()
    const options: any = {
      // top_p: 1,
      temperature: config.temperature,
      topP: config.topP
    }
    if (expectsJson && model === OPENAI_40_TURBO_MODEL_NAME) // only works with turbo
      options.responseFormat = { type: "json_object" }
    if (!config.isAzure)
      options.model = model // applicable if using OpenAI; ignored for AzureOpenAI 

    const response = await this.openai.chat.completions.create({
      model,
      chatMsgs,
      ...options
    })

    const elapsedMsec = Date.now() - startmsecs

    this.logger.log(`response took ${elapsedMsec} msecs ${JSON.stringify(response)}`)
    return {
      message: response.choices[0].message,
      finish_reason: response.choices[0].finish_reason,
      input_usage: response.usage?.prompt_tokens,
      output_usage: response.usage?.completion_tokens,
      usage: response.usage?.total_tokens,
      elapsedMsec,
      temperature: config.temperature,
      top_p: config.topP,
      model
    } as OpenAIResult

  }
  protected generateAsString(val: any) {
    return typeof val === 'string' ? val : JSON.stringify(val)
  }
}

export interface AzureServerConfig  {
    env: string,
    endpoint: string,
    key: string,
    models: string[],
    defaultModel?: string

}

export const AZURE_OPENAI_SERVERS: AzureServerConfig[] = []

export const AZURE_DOCINTEL_SERVERS = []

export function getAzureOpenAIServer(env: "dev" | "prod"): AzureServerConfig {
    //console.log("Azure End Point lookup env", env)
    if (['dev', 'prod'].includes(env))
        return AZURE_OPENAI_SERVERS.find( server => server.env === env )
    else
        throw new Error('Internal Error: Invalid environment specified in Azure server lookup')    
}