import { UserRecord } from '../user.models';
import {
  FlowFile,
  FlowStep,
  FlowTool,
  Provider,
} from '../fileflow.interface';
import { FileflowServiceInterface } from '../fileflow.interface';
import { JsonFormData } from '@cheaseed/node-utils';
import { DOCUPANDA_CREDIT_COST, DOCUPANDA_CREDIT_OVERAGE_COST, generateDocupandaPageOutputPreview } from '../docupanda-utils';
import { expandPageRange } from '../utils';

export class DocuPandaTool implements FlowTool {
    name = 'standard-ocr'
    description = 'Data Extraction (standard)'
    type = 'ocr'
    processingMethod = '' // override in subclasses
    startTime = 0
    
    parameters: JsonFormData = {
      submitAlwaysEnabled: true,
      controls: [ 
        { 
          name: 'pages', 
          label: 'Page range',
          type: 'text', 
          placeholder: '(e.g. 1-3,6,10)' 
        },        
        {          
          name: 'processingMethod',
          label: 'Processing method',
          type: 'radio',
          choices: [
            { value: 'asImage' },
            { value: 'removeWatermark' }
          ]
        }
      ]
    }
    //firebase = inject(FirebaseService)
    flowService: FileflowServiceInterface

    constructor(flowService?: FileflowServiceInterface) {
      if(flowService)
        this.initialize(flowService)
    }
    initialize(flowService: FileflowServiceInterface) {
      this.flowService = flowService
      return this
    }

    async checkExecute(file: FlowFile, user: UserRecord) {
      await this.flowService.defaultCheckExecute(file, user, file.numPages)
    }
  
    shouldPublish(): boolean {
        return true // TODO: false if we switch to webhook notification of completion
    }

    async execute(
        file: FlowFile,
        last: FlowStep | null,
        params?: any) 
    {
        this.startTime = Date.now()
        this.flowService.log(`ocr with ${this.name}`, file, params)

        const result = await this.postDocumentToDocupanda(file, this.processingMethod, params)
        // account for the fact that we could receive the data as res.data (if this instance
        // is running locally or just res if this instance is running in the cloud)
        const response = result.data ? result : { data: result }

        const externalDocumentId = (response?.data as any)?.documentId
        const numPages = (response?.data as any)?.result.numPages
        const userId = this.flowService.getUserId()
        const user = await this.flowService.getUser(userId)
        // Broadcast the number of pages consumed
        this.flowService.consumedPages$.next(numPages)
        this.flowService.log(
          'scan complete',
          file.fileName,
          `numPages (${numPages})`,
          'response length', JSON.stringify(response.data).length,
        )
        // const output = JSON.stringify(response.data)
        await this.flowService.uploadAnalysis(this, file, response)
        // await this.flowService.updateStep(file, { name: this.name, output });
        await this.flowService.updateFile(file, { numPages, externalDocumentId })
        const balance = await this.flowService.getPageBalance(user)
        await this.flowService.logPipelineStep({
          user: userId,
          groupDocId: user?.groupDocId, 
          fileDocId: file.docId,
          fileName: file.fileName,
          loggedAt: new Date(),
          fileSize: file.size,
          numPages: numPages,
          stepName: this.name,          
          credits: numPages,
          cost: numPages * (balance < 0 
            ? DOCUPANDA_CREDIT_OVERAGE_COST 
            : DOCUPANDA_CREDIT_COST),
          elapsedMsec: Date.now() - this.startTime
        })
        return true
    }

    getContentDisposition(fileName: string) {
      return  'inline;'
    }

    async postDocumentToDocupanda(file: FlowFile, processingMethod: string, params?: any) {
      const base64 = await this.flowService.getFileContentsBase64(file.storageName)
      const body: any = {
          dataset: params.production ? 'prod' : 'dev',
          document: {
              file: {
                  filename: file.fileName,
                  contents: base64
              }
          },
          parseVersion: 2
      }
      if (params?.pages) {
          const nums = expandPageRange(params.pages)
          body.pages = nums.map(page => page > 0 ? page - 1 : 0) // Convert to 0-based index
          this.flowService.log('updated body', body)
      }
      if (processingMethod)
          body.processingMethod = processingMethod
      else if (params?.processingMethod) {
          body.processingMethod = params.processingMethod
      }
      this.flowService.log('Body Parameters', JSON.stringify({ ...body, document: undefined }))
      const postResponse =  await this.flowService.postDocument({
        provider: Provider.DOCUPANDA,
        body
      })
      console.log('Post response', JSON.stringify(postResponse))
      const documentId = (postResponse.data || postResponse).documentId
      const response =  await this.flowService.pollJob({
        provider: Provider.DOCUPANDA,
        documentId,
        interval: 5000
      })      
      this.flowService.log('Poll response', JSON.stringify(response))
      return response
  }

    generateOutputPreview(output: any) {
        return generateDocupandaPageOutputPreview(output)
    }
}
