<div class="flex flex-row items-center justify-between sm:min-w-96 hover:bg-gray-50 cursor-pointer" (click)="fileFlowService.toggleFileOpened(file)">
    <span class="flex-none material-symbols-outlined hover:bg-slate-200 cursor-pointer text-3xl px-2">
      @if (file.isFolder) {
        {{ opened() ? 'folder_open' : 'folder' }}
      }
      @else {
        {{ opened() ? 'arrow_drop_down' : 'arrow_right' }}
      }
    </span>
    @if (file.state === 'idle') {
      <span class="grow text-sm sm:text-lg truncate">
        {{ file.fileName }}        
      </span>
    }
    @else {
      <span class="grow flex flex-row gap-1 items-center truncate">
        <span class="text-sm sm:text-lg">{{ file.fileName }}</span>
        @if (file.state === 'error') {
          <span class="truncate text-xs text-red-500">{{ file.stateDescription }}</span>
        }
        @else {
          <span class="px-2 flex flex-row gap-2 items-center">
            <mat-spinner strokeWidth="2" diameter="20"/>
            <span class="truncate text-xs">{{ file.stateDescription }}</span>
          </span>
        }
      </span>
    }    
    <div class="flex-none px-2 flex items-center gap-2">
      <span *ngIf="file.numPages" class="px-2 text-xs align-text-top text-gray-400">{{ file.numPages }} {{ file.numPages > 1 ? 'pages' : 'page' }}</span>
      <span *ngIf="adminView" class="grow text-sm sm:text-xs truncate">{{ file.userDocId }}</span>
      <span *ngIf="!file.isFolder"
        matTooltip="View input and outputs"
        class="material-symbols-outlined hover:bg-slate-200 cursor-pointer text-2xl text-blue-600"
        (click)="launchPreview(file); $event.stopPropagation()">
        visibility
      </span>
      @if (!file.isFolder && file.outputType === 'xls-transformer') {
        <span
          matTooltip="View output document"
          class="material-symbols-outlined hover:bg-slate-200 cursor-pointer text-2xl text-green-700"
          (click)="fileFlowService.downloadOutputFile(file); $event.stopPropagation()">
          table_view
        </span>        
      }
      <span
        *ngIf="file.outputType === 'xls-transformer'"
        class="material-symbols-outlined hover:bg-slate-200 cursor-pointer text-2xl"
        [style.color]="file.rating! < 0 ? 'red' : file.rating! > 0 ? 'green' : 'black'"
        (click)="$event.stopPropagation()"
        [matMenuTriggerFor]="ratingsMenu">
        {{ file.rating! < 0 ? 'thumb_down' : 'thumb_up' }}
      </span>
      <mat-menu #ratingsMenu="matMenu" yPosition="above" xPosition="after">
        <ng-template matMenuContent>
          <div class="flex flex-row items-center justify-center gap-3">
            <span class="material-symbols-outlined hover:bg-slate-200 cursor-pointer text-2xl text-green-500" (click)="setRating(file, 1)">
              thumb_up
            </span>
            <span class="material-symbols-outlined hover:bg-slate-200 cursor-pointer text-2xl text-red-500" (click)="setRating(file, -1)">
              thumb_down
            </span>
            <span class="material-symbols-outlined hover:bg-slate-200 cursor-pointer text-2xl">
              close
            </span>
          </div>
        </ng-template>
      </mat-menu>
      <mat-menu #sourceFileMenu="matMenu" yPosition="below" xPosition="after">
        <ng-template matMenuContent>
          <button mat-menu-item (click)="viewFileInBrowser(file)">
            <span>View</span>
          </button>
          <button mat-menu-item (click)="fileFlowService.downloadSourceFile(file)">
            <span>Download</span>
          </button>
        </ng-template>
      </mat-menu>

      <div class="text-xs text-center w-40 hidden sm:block">
        {{ file.isFolder ? 'created' : 'uploaded' }} {{ file.createdAt | dfnsFormatDistanceToNow: { addSuffix: true } }}
      </div>
      <button *ngIf="!readOnly" [matMenuTriggerFor]="fileMenu"
        (click)="$event.stopPropagation()"
        class="max-w-xs items-center">
        <span *ngIf="!readOnly" class="material-symbols-outlined hover:bg-slate-200 cursor-pointer text-xl">
          more_vert
        </span>
      </button>
      <mat-menu #fileMenu="matMenu" xPosition="before" class="w-64">
        <ng-template matMenuContent>
          @if (file.isFolder) {
            <lib-folder-menu [folder]="file"/>
          }
          @else {
            @if (userService.adminRole()) {
              <button mat-menu-item (click)="executeSelect()">
                <mat-icon class="material-symbols-outlined">add</mat-icon>
                <span class="text-blue-500">Execute</span>
              </button>
              <button mat-menu-item (click)="copyShareLink(file)">
                <mat-icon class="material-symbols-outlined">share</mat-icon>
                <span class="text-blue-500">Share</span>
              </button>
              <button mat-menu-item (click)="fileFlowService.confirmDeleteFile(file, true)">
                <mat-icon class="material-symbols-outlined">delete_sweep</mat-icon>
                <span class="text-blue-500">Delete Steps</span>
              </button>
              <button mat-menu-item (click)="fileFlowService.inspect(file.fileName, file)">
                <mat-icon class="material-symbols-outlined">visibility</mat-icon>
                <span class="text-blue-500">Inspect</span>
              </button>                
            }
            <button *ngIf="!file.isFolder && (file.state === 'idle' || file.state === 'error')" mat-menu-item (click)="openFeedbackModal.set(true)">
              <mat-icon class="material-symbols-outlined">comment</mat-icon>
              <span>Give Feedback</span>
            </button>
            <button *ngIf="!adminView" mat-menu-item [matMenuTriggerFor]="moveMenu">
              <mat-icon class="material-symbols-outlined">move</mat-icon>
              <span>Move</span>
            </button>
            <mat-menu #moveMenu="matMenu">
              @if (!adminView) {
                <button mat-menu-item (click)="fileFlowService.confirmMoveFile(file, '')">To My Files</button>
                  @for (fldr of fileFlowService.availableFolders(); track fldr) {
                    <button mat-menu-item (click)="fileFlowService.confirmMoveFile(file, fldr)">{{fldr}}</button>
                  }
              }
            </mat-menu>
            <button mat-menu-item (click)="fileFlowService.renameFolder(file)">
              <mat-icon class="material-symbols-outlined">edit</mat-icon>
              <span>Rename</span>
            </button>
            <button mat-menu-item (click)="fileFlowService.confirmDeleteFile(file)">
              <mat-icon class="material-symbols-outlined">delete</mat-icon>
              <span>Delete</span>
            </button>
          }
        </ng-template>
      </mat-menu>
    </div>
  </div>
  @if (opened()) {
    <div class="p-2 px-4">
      <lib-file-steps [file]="file" [readOnly]="readOnly" [steps]="steps$ | async"/>
    </div>    
  }

<ion-modal #fileViewerModal
  [isOpen]="!!openFileViewerModal()" 
  canDismiss="true"
  (didDismiss)="openFileViewerModal.set(false)"
  (willDismiss)="utilityService.willDismiss()"
  (willPresent)="utilityService.willPresent()"
  style="--height: 80vh; --width: 100%; --min-width: 600px; --max-width: 1200px; z-index: 9;">
  <ng-template>
    <ion-content style="height: 80vh">
      <div class="flex flex-col items-center p-4">
        <div class="flex w-full justify-between items-center">
          <div class="text-xl font-bold p-2 py-4">
            {{ file.fileName }}
          </div>
          <div class="flex gap-2 text-sm md:text-base">
            <div 
              class="border cursor-pointer bg-slate-200 hover:bg-slate-400" 
              (click)="fileFlowService.downloadSourceFile(file)">
              <div class="p-2 text-center align-middle">Download Input</div>
            </div>
            <div *ngIf="file.outputStorageName" 
              class="border cursor-pointer bg-slate-200 hover:bg-slate-400" 
              (click)="fileFlowService.downloadOutputFile(file)">
              <div class="p-2 text-center align-middle">Download Output</div>
            </div>
            <div 
              class="text-right cursor-pointer hover:bg-slate-200" 
              (click)="popoutFileViewer(); fileViewerModal.dismiss()">
              <span class="material-symbols-outlined text-xl">open_in_full</span>
            </div>
            <div 
              class="text-right cursor-pointer hover:bg-slate-200" 
              (click)="fileViewerModal.dismiss()">
              <span class="material-symbols-outlined text-xl">close</span>
            </div>
          </div>
        </div>
        <div class="flex w-full justify-between items-start gap-2">
          <div class="min-w-[50%]">
            @if (file.fileName.endsWith('pdf')) {
              <ngx-extended-pdf-viewer 
                [src]="openFileViewerModal()"              
                zoom="60"
                [showToolbar]="true"
                [enablePrint]="true"
                [showPrintButton]="true"
                [showDownloadButton]="false"
                [showOpenFileButton]="false"
                [showSidebarButton]="false"
                [showHandToolButton]="false"                    
                [textLayer]="false"
                [contextMenuAllowed]="false"
                [showRotateButton]="false"
                [showDrawEditor]="false"
                [showHighlightEditor]="false"
                [showTextEditor]="false"
                [showStampEditor]="false"
                [showSecondaryToolbarButton]="false"
                [filenameForDownload]="file.fileName">
              </ngx-extended-pdf-viewer>
            }
            @else if (['jpeg', 'jpg', 'png'].includes(file.fileName.split('.').pop()!)) {
              @if (openFileViewerModal(); as src) {
                <img [src]="src"/>
              }
            }
          </div>
          <div class="min-w-[50%]">
            <lib-file-schema-viewer 
                [file]="file"
                (selectedText)="onSelectedText($event)"/>
          </div>
        </div>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>

<ion-modal #feedbackModal
  [isOpen]="openFeedbackModal()" 
  canDismiss="true"
  (didDismiss)="openFeedbackModal.set(false)"
  style="--height: 48vh; --width: 85%; --min-width: 300px; --max-width: 700px">
  <ng-template>
    <ion-content style="height: 48vh">
      <div class="flex flex-col gap-3 p-8">
        <div class="text-xl">Please provide feedback on the output for {{ file.fileName }} </div>
        <textarea #feedbackText class="w-full h-20 sm:h-40 border border-gray-300 rounded-md p-4"></textarea>
        <button mat-raised-button class="w-40" (click)="submitFeedback(file, feedbackText.value); feedbackModal.dismiss()">Submit</button>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>

<ion-modal #executeSelector
  [isOpen]="openExecuteSelector()" 
  canDismiss="true"
  (didDismiss)="openExecuteSelector.set(false)"
  style="--height: 80vh; --width: 85%; --min-width: 300px; --max-width: 700px">
  <ng-template>
    <ion-content style="height: 80vh">
      <ng-container *ngIf="steps$ | async as steps">
        <div class="font-bold text-blue-600 bg-yellow-100 p-2 text-center">Pipelines</div>
        <div class="flex flex-col gap-3 p-3">
          @for (
            button of pipelineMenuOptions();
            track button.label) {
              <button
                mat-raised-button
                [disabled] = "!!button.disabled"
                (click)="selectAction(button, file, steps); executeSelector.dismiss()">
                {{ button.label }}
              </button>
          }
        </div>
        <div class="font-bold text-blue-600 bg-yellow-100 w-full p-2 text-center">Tools</div>
        <div class="flex flex-col gap-3 p-3">
          @for (
            button of toolMenuOptions(steps);
            track button.label) {
              <button
                mat-raised-button
                [disabled] = "!!button.disabled"
                (click)="selectAction(button, file, steps); executeSelector.dismiss()">
                {{ button.label }}
              </button>
          }
        </div>
      </ng-container>
    </ion-content>
  </ng-template>
</ion-modal>