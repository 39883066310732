import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { GroupService } from '../groups/group.service';
import { map, switchMap, combineLatestWith, debounceTime, tap, filter, distinctUntilKeyChanged } from 'rxjs';
import { UserService } from '@fidoc/util';
import { MatButtonModule } from '@angular/material/button';
import { LabelledSpinnerComponent } from '@cheaseed/shared/ui';

@Component({
  selector: 'lib-group-invite',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    LabelledSpinnerComponent
],
  templateUrl: './group-invite.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupInviteComponent {

  private route = inject(ActivatedRoute)
  private userService = inject(UserService)
  groupService = inject(GroupService)
  groupId$ = this.route.paramMap.pipe(map(params => params.get('groupId') as string))

  data$ = this.userService.user$
    .pipe(
      filter(user => !!user),
      distinctUntilKeyChanged('docId'), // to prevent update after group join
      combineLatestWith(this.groupId$),
      switchMap(([user, groupId]) => this.groupService.getGroup(groupId)
        .pipe(
          map(group => ({ 
            user, 
            group,
            isAlreadyMember: group.docId && user?.groupDocId === group.docId
          }))
        )),
      // tap(console.log),
      debounceTime(400)
    )
  }