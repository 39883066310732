import { UserRecord } from '../user.models';
import {
  FlowFile,
  FlowStep,
  FlowTool,
  FileflowServiceInterface,
  Provider,
} from '../fileflow.interface';
import { JsonFormData } from '@cheaseed/node-utils';
import {
  getLlamaIndexResult,
  LLAMAINDEX_CREDIT_COST,
  LLAMAINDEX_CREDIT_OVERAGE_COST
} from '../llamaindex-utils';

export class LlamaParseTool implements FlowTool {
  name = 'lp-ocr';
  description = 'Data Extraction (lp)';
  type = 'ocr';
  processingMethod = ''; // override in subclasses
  startTime = 0;

  parameters: JsonFormData = {
    submitAlwaysEnabled: true,
    controls: [
      {
        name: 'pages',
        label: 'Page range',
        type: 'text',
        placeholder: '(e.g. 1-3,6,10)',
      },
    ],
  };
  //firebase = inject(FirebaseService)
  flowService: FileflowServiceInterface;

  constructor(flowService?: FileflowServiceInterface) {
    if (flowService) 
        this.initialize(flowService);
  }
  initialize(flowService: FileflowServiceInterface) {
    this.flowService = flowService;
    return this;
  }
  shouldPublish(): boolean {
    return false; // true;
  }
  async checkExecute(file: FlowFile, user: UserRecord) {
    await this.flowService.defaultCheckExecute(file, user, file.numPages)
  }

  async execute(file: FlowFile, last: FlowStep | null, params?: any) {
    this.startTime = Date.now();
    this.flowService.log(`ocr with ${this.name}`, file, params);

    // postDocumentToLlamaIndex(file: FlowFile, params?: any): Promise<any>
    // getLlamaIndexResult(jobId: string, type: string): Promise<any>

    const postResponse =  await this.flowService.postDocument({
      provider: Provider.LLAMAPARSE,
      storagePath: file.storageName,
      fileName: file.fileName
    })
    this.flowService.log('Post response', postResponse)
    const jobId = (postResponse.data || postResponse).id
    const response = await this.flowService.pollJob({
      provider: Provider.LLAMAPARSE,
      jobId,
      interval: 5000
    })
    this.flowService.log('Poll response', response)
    const { job_metadata, text, markdown } = (response.data || response)
    const numPages = job_metadata.job_pages;
    const credits = job_metadata.job_credits_usage;
    const userId = this.flowService.getUserId();
    const user = await this.flowService.getUser(userId);

    // Broadcast the number of pages consumed
    this.flowService.consumedPages$.next(numPages)
    const output = JSON.stringify({ text, markdown }, null, 2)
    this.flowService.log(
      'scan complete',
      file.fileName,
      `numPages (${numPages})`,
      'response length',
      output.length,
    )
    await this.flowService.uploadAnalysis(this, file, response)
    await this.flowService.updateStep(file, {
      name: this.name,
      description: this.description,
      elapsedMsec: Date.now() - (this.startTime || 0),
      state: 'complete',
      error: undefined
    });
    await this.flowService.updateFile(file, {
      numPages,
      externalDocumentId: jobId,
      outputType: this.type,
    });
    await this.flowService.logPipelineStep({
      user: userId,
      groupDocId: user?.groupDocId, 
      fileDocId: file.docId,
      fileName: file.fileName,
      loggedAt: new Date(),
      fileSize: file.size,
      numPages: numPages,
      stepName: this.name,
      credits: credits,
      cost: credits *
        (await this.flowService.getPageBalance(user) < 0
          ? LLAMAINDEX_CREDIT_OVERAGE_COST
          : LLAMAINDEX_CREDIT_COST),
      elapsedMsec: Date.now() - this.startTime,
    });
    return true;
  }

    getContentDisposition(fileName: string) {
        return 'inline;'
    }

    generateOutputPreview(output: any) {
        return [{
            tabLabel: 'Text',
            pageNum: 1,
            textOnly: true,
            sections: output.text.split('\n').map((line: string, i: number) => ({
                type: 'text',
                text: line.trim(),
                key: i,
                tableList: []
            }))}]
    }
}
