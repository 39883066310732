import { PipelineEnvironment, UserRecord } from './user.models';
import { DocuPandaTool } from './tools/DocuPandaTool';
import { DocIntelGenerateXlsTool } from './tools/DocIntelGenerateXlsTool';
import { DocIntelMarkdownTransformerTool } from './tools/DocIntelMarkdownTransformerTool';
import { DocIntelTool } from './tools/DocIntelTool';
import { DocuPandaAsImageTool } from './tools/DocuPandaAsImageTool';
import { DocuPandaGenerateXlsTool } from './tools/DocuPandaGenerateXlsTool';
import { DocuPandaJsonIndenterTool } from './tools/DocuPandaJsonIndenterTool';
import { DocuPandaRemoveWatermarkTool } from './tools/DocuPandaRemoveWatermarkTool';
import { DocuPandaTextTableToMarkdownTool } from './tools/DocuPandaTextTableToMarkdownTool';
import { FileflowServiceInterface, FlowFile, FlowStep, FlowPrompt, PipelineStepLog } from './fileflow.interface';
import { DocuPandaStandardizationTool } from './tools/DocuPandaStandardizationTool';
import { DocuPandaStandardizationXlsTool } from './tools/DocuPandaStandardizationXlsTool';
import { LlamaParseTool } from './tools/LlamaParseTool';

export const DEFAULT_ASSISTANT_MODEL = 'gpt-4o';

export const INITIAL_PAGE_BALANCE = 500

export enum MailProviders {
  SENDGRID = 'sendgrid',
  MAILGUN = 'mailgun'
}

export const PIPELINE_ENV_KEY = 'pipelines.executionEnvironment'
export const FIDOCS_PIPELINE_TOPIC = 'fidocs-pipeline'
export const NO_PIPELINE = '<None>'
export const NO_TOOL = '<None>'

export const CLOUD_FUNCTION_TIMEOUT = 540 * 1000

export function isExecEnvCloud(user: UserRecord, systemSetting: PipelineEnvironment) {
  if(user.pipelineEnv) {
    return user.pipelineEnv === PipelineEnvironment.PIPELINE_EXEC_ENV_CLOUD
  }
  return systemSetting === PipelineEnvironment.PIPELINE_EXEC_ENV_CLOUD
}

export const flowStepConverter = {
  toFirestore(f: FlowStep) {
    return f;
  },
  fromFirestore(snapshot: any): FlowStep {
    const obj: any = snapshot.data();
    return {
      ...obj,
      lastUpdatedAt: obj.lastUpdatedAt?.toDate(),
    } as FlowStep;
  },
};


//let g_toolsRegistry: FlowTool[]
export function getFlowToolsRegistry(flowService: FileflowServiceInterface) {
  //if(!g_toolsRegistry)
  //g_toolsRegistry = 
  return [
    new DocuPandaTool(flowService),                      // 0
    new DocuPandaRemoveWatermarkTool(flowService),       // 1
    new DocuPandaAsImageTool(flowService),               // 2
    new DocIntelTool(flowService),                       // 3
    new DocuPandaJsonIndenterTool(flowService),          // 4
    new DocuPandaGenerateXlsTool(flowService),           // 5
    new DocuPandaTextTableToMarkdownTool(flowService),   // 6
    new DocIntelMarkdownTransformerTool(flowService),    // 7
    new DocIntelGenerateXlsTool(flowService),            // 8
    new DocuPandaStandardizationTool(flowService),       // 9
    new DocuPandaStandardizationXlsTool(flowService),    // 10
    new LlamaParseTool(flowService)                      // 11
    /*inject(DocIntelJSONTool),
    inject(DocIntelJSONTransformerTool),
    inject(DocIntelJSONGenerateXlsTool)
    */
  ]
  //return g_toolsRegistry
}
export const flowFileConverter = {
  toFirestore(f: FlowFile) {
    return f;
  },
  fromFirestore(snapshot: any): FlowFile {
    const obj: any = snapshot.data();
    return {
      ...obj,
      createdAt: obj.createdAt?.toDate(),
      updatedAt: obj.updatedAt?.toDate(),
    } as FlowFile;
  },
};

export const flowStepPromptConverter = {
  toFirestore(f: FlowPrompt) {
    return f
  },
  fromFirestore(snapshot: any): FlowPrompt {
    const obj: any = snapshot.data()
    return {
      ...obj,
      createdAt: obj.createdAt?.toDate(),
    } as FlowPrompt
  }
};


export const CostRecordConverter = {    
    toFirestore(sub: PipelineStepLog) { 
      return { ...sub } 
    },
    fromFirestore(snapshot: any): PipelineStepLog {
      return this.fromFirestoreData(snapshot.data())
    },
    fromFirestoreData(data: any): PipelineStepLog {
        return {
            ...data,
            loggedAt: data.loggedAt?.toDate(),
            } as PipelineStepLog
    },
    fromArray(data: any[]): PipelineStepLog[] {
      return data.map(d => this.fromFirestoreData(d))
    }
  }