import {
  AgentType,
  FlowFile,
  FlowStep,
  FlowTool,
} from '../fileflow.interface'
//import { FileflowService } from '../fileflow.service'
import { defaultTransformerParameters, executeAzureTool } from '../utils'
import { FileflowServiceInterface } from '../fileflow.interface'
import { JsonFormData } from '@cheaseed/node-utils'

export class DocuPandaTextTableToMarkdownTool implements FlowTool {

  //firebase = inject(FirebaseService)
  flowService: FileflowServiceInterface

  name = 'text-table-to-markdown'
  type = 'transformer'
  apiType = AgentType.completions
  description = 'Transform the specified text table into a valid markdown table'
  instructions = 'Transform the following text table into a valid markdown table'
  parameters: JsonFormData
  precedents: FlowTool[] = [] //[ inject(DocuPandaTool) ]

  constructor(flowService?: FileflowServiceInterface) {
    if(flowService)
      this.initialize(flowService)
  }

  initialize(flowService: FileflowServiceInterface) {
    this.flowService = flowService
    this.parameters = defaultTransformerParameters
    return this
  }

  shouldPublish(): boolean {
      return false
  }
  async execute(
    file: FlowFile,
    last: FlowStep | null,
    params?: any
  ) {
    const output = last ? await this.flowService.getFileContents(last.outputURL) : null;
    if (!output)
      throw new Error(`No output found in last step ${last?.name} ${last?.outputURL}`)

    if (params?.saveGlobally) {
      this.instructions = params.instructions
      this.flowService.updateTool(this, { instructions: params.instructions })
    }
    
    // Correct the indentation of the tables in the output
    const pages = output.data.result.pages;
    for (let i = 0; i < pages?.length; i++) {
      const page = pages[i];
      const sections = page.sections;
      for (let j = 0; j < sections.length; j++) {
        if (sections[j].type === 'table') {
          this.flowService.log(`Found table in page ${i} section ${j}`);
          const ascii = sections[j].tableAscii;
          const markdown = await this.convertTextTableToMarkdown(ascii, params.production);
          sections[j].tableAscii = markdown;
        }
      }
    }
    // Update the FlowStep
    await this.flowService.uploadAnalysis(this, file, pages);
  }
  
  private async convertTextTableToMarkdown(textTable: string, isProduction: boolean) {
    const messages = [
      {
        role: 'user',
        content: `${textTable}`,
      },
    ];
    const result = await executeAzureTool(
      this,
      messages,
      this.flowService,
      { instructions: this.instructions, isProduction }
    );
    return result
  }

  getContentDisposition(fileName: string) {
    return 'inline;'
  }
}
