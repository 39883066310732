import { BackoffOptions, backOff } from 'exponential-backoff';
import { FileflowServiceInterface, FlowFile, FlowSchemaType } from "./fileflow.interface";
import { delay, stringifyKeysInOrder } from "./utils"

export const DOCUPANDA_KEY = 'v4dsiydswLT8eJg0BB7GaSJoSE83';
export const DOCUPANDA_DOCUMENT_ENDPOINT = 'https://app.docupanda.io/document';
export const DOCUPANDA_CLASSES_ENDPOINT = 'https://app.docupanda.io/classes';
export const DOCUPANDA_SCHEMAS_ENDPOINT = 'https://app.docupanda.io/schemas';
export const DOCUPANDA_CREDIT_COST = 0.0396;
export const DOCUPANDA_CREDIT_OVERAGE_COST = 0.08;

//constants for exponential backoff
const BACK_OFF_DELAY = 5000;
const BACK_OFF_TIME_MULTIPLE = 2;
const BACK_OFF_JITTER = 'full';
const BACK_OFF_NUM_OF_ATTEMPTS = 5;
const BACK_OFF_MAX_DELAY = 300000;
const DOCUPANDA_RATE_LIMIT_RESPONSE_STATUS = 429;
export class DPRateLimitError extends Error {}
export function getFetchHeaders() {
  return {
      "accept": 'application/json', 
      "content-type": 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
      "X-API-Key": DOCUPANDA_KEY
  }
}

export async function get(url: string) {
  const method = 'GET',
    headers = getFetchHeaders(),
    options = { method, headers }
  console.log(`${method} ${url}`)

  const backOffOptions: BackoffOptions = {
    delayFirstAttempt: false,
    startingDelay: BACK_OFF_DELAY,
    timeMultiple: BACK_OFF_TIME_MULTIPLE,
    jitter: BACK_OFF_JITTER,
    numOfAttempts: BACK_OFF_NUM_OF_ATTEMPTS,
    retry(e: any, attemptNumber: number) {
      console.warn(`Retrying after error from fetch. Attempt Number: ${attemptNumber}`, e)
      return e instanceof DPRateLimitError
    },
    maxDelay: BACK_OFF_MAX_DELAY
  }
  let res: Response | null = null
  try {
    res = await backOff(() => { return fetch(url, options) }, backOffOptions)
  }
  catch(e) {
    if(res && res.status === DOCUPANDA_RATE_LIMIT_RESPONSE_STATUS) {
      throw new DPRateLimitError(`Rate limit error detected while fetching ${url}`, e)
    }
    else {
      throw e
    }
  }
  const json = await res.json()
  return json
}

export async function del(url: string) {
  const   method = 'DELETE',
          headers = getFetchHeaders(),
          options = { method, headers }
  console.log(`${method} ${url}`)
  const res = await fetch(url, options)
  const json = await res.json()
  return json
}

export async function post(url: string, body: any, debug = false) {
  const   method = 'POST',
          headers = getFetchHeaders(),
          bodystr = JSON.stringify(body),
          options = { method, headers, body: bodystr }
  console.log(`${method} ${url}${debug ? ' - ' + stringifyKeysInOrder(body) : ''}`)
  const res = await fetch(url, options)
  const json = await res.json()
  if (res.status !== 200) 
    throw new Error(`${res.status} : body ${bodystr} : result ${JSON.stringify(json)}`)
  return json
}

export async function docupandaPost(payload: any) {
  return await post(DOCUPANDA_DOCUMENT_ENDPOINT, payload.body)
}

export async function docupandaPoll(documentId: string, interval: number, timeout: number) {
  return await poll(`${DOCUPANDA_DOCUMENT_ENDPOINT}/${documentId}`, interval, timeout)
}

async function poll(url: string, interval: number = 1000, timeout: number = 600000) {
  let totalDelay = 0
  let data:any = { status: 'initializing' }
  while (data.status !== 'completed' && totalDelay < timeout) {
    if (data.status === 'error') {
      throw new Error(`Error detected while polling ${url}: ${JSON.stringify(data)}`)
    }
    console.log(`... poll ${data.status} ${url}`)
    await delay(interval)
    totalDelay += interval
    try {
      data = await get(url)
    }
    catch(e) {
      console.warn(`... error polling ${url}`)
    }
  }
  if (totalDelay >= timeout)
    throw new Error(`Timeout detected while polling ${url}`)
  return data
}

export async function getDocupandaClasses() {
  return await get(DOCUPANDA_CLASSES_ENDPOINT)
}

export async function getDocupandaSchemas() {
  return await get(DOCUPANDA_SCHEMAS_ENDPOINT)
}

export async function standardize(documentId: string, classNameMap: Map<string, FlowSchemaType> | undefined) {
  const classIdMap = new Map<string, any>(Array.from(classNameMap!.values()).map(c => [c.classId, c]))
  // Get classes for document
  const doc = await get(`https://app.docupanda.io/document/${documentId}`)
  console.log(`Standardizing ${documentId} ${doc.result.numPages} pages ${doc.classified ? 'classified to ' + doc.classIds.length + ' classes' : 'not classified'}`)
  // For each class, look up schemaId for class and create standardization
  const results:any[] = []
  for (const classId of doc.classIds) {
      const cls = classIdMap.get(classId as string)
      const schemaId = cls.schemaId || classNameMap?.get(cls.useSchemaOfClass)?.schemaId
      console.debug('standardizing on class', schemaId, cls.className)
      // Skip if no schemaId
      if (!schemaId)
        continue
      const body = {
          schemaId,
          standardizationMode: "default",
          forceRecompute: true,
          documentIds: [ documentId ]
      }
      const result = await post(`https://app.docupanda.io/standardize/batch`, body)
      console.debug('standardize post result', result)
      const pollResults:any[] = await Promise.all(result.standardizationJobIds.map((jobId: string) => poll(`https://app.docupanda.io/job/${jobId}`, 1000)))
      console.debug('standardize job results', pollResults)
      const stds = await Promise.all(result.standardizationIds.map((stId: string) => get(`https://app.docupanda.io/standardization/${stId}`)))
      console.debug('standardize lookup results', stds)
      results.push({ className: cls.className, standardization: stds[0] })
  }
  return results
}

export function getDocupandaSplitInstructions(classMap: Map<string, any>, classIds: string[]) {
  const classes:any[] = Array.from(classMap.values()).filter(c => classIds.includes(c.classId))
  console.debug('getSplitInstructions', classes, classMap, classIds)
  const instructions = classes.map(c => `- ${c.description}`).join('\n')
  return 'Generate subdocuments for each of the following classes of documents if present:\n\n' + instructions
}

export async function split(documentId: string, instructions: string) {
  const result = await post(`https://app.docupanda.io/split`, { documentId, instructions })
  const pollResults = await poll(`https://app.docupanda.io/split/${result.jobId}`)
  return pollResults.newDocumentIds
}

export async function classify(documentIds: string[], classNameMap: Map<string, FlowSchemaType> | undefined) {
  console.log('classify', classNameMap)
  const classIdMap = new Map<string, any>(Array.from(classNameMap!.values()).map(c => [c.classId, c]))
  const result = await post(`https://app.docupanda.io/classify/batch`, { 
      multiClass: true,
      includeUnknown: true,
      documentIds })
  console.debug('classify post result', result)
  const pollResults:any[] = await Promise.all(result.classificationJobIds.map((jobId: string) => poll(`https://app.docupanda.io/classify/${jobId}`)))
  const final = pollResults.map((job: any) => {
    const classes = job.assignedClassIds.map((classId: string) => classIdMap.get(classId))
    return { ...job, classes }
  })
  console.debug('classify jobs results', final)
  return final
}

export async function getDocupandaOutput(file: FlowFile, flowService: FileflowServiceInterface) {
  const steps = await flowService.getFileSteps(file)
  const ocr = steps[0] ? await flowService.getFileContents(steps[0].storageName) : null
  return ocr
}

export function generateDocupandaPageOutputPreview(output: any) {
    return output.data.result?.pages.map((page: any) => ({
        tabLabel: `Page ${page.pageNum + 1}`,
        pageNum: page.pageNum,
        sections: page.sections.map((section: any) => ({
            type: section.type,
            text: section.text,
            tableList: section.tableList
        }))
    }))
}
