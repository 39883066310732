import { Inject, Injectable, computed, inject, signal } from '@angular/core'
import { orderBy, where } from '@angular/fire/firestore'
import { 
  MAX_GROUP_NAME_LENGTH, 
  MIN_GROUP_NAME_LENGTH, 
  splitTrim2,
  Group, 
  GroupConverter, 
  GroupMember, 
  GroupMemberConverter,
  GroupLedgerEntry,
  GROUP_LEDGER_COLLECTION_NAME,
  UserRecord,
  FirestoreCollectionTypes,
  delay,
  MailProviders,
  CostRecordConverter,
  GROUP_INVOICES_COLLECTION_NAME
} from '@fidoc/shared'

import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, distinctUntilKeyChanged, filter, firstValueFrom, map, of, shareReplay, switchMap, tap } from 'rxjs'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { Router } from '@angular/router'
import { Environment, FirebaseService, UserService, DefaultsService } from '@fidoc/util';
import { connect } from 'ngxtension/connect';
import { PortalUtilityService } from '@cheaseed/portal/util'
import { all } from 'axios'

export interface GroupsState {
  // Current user group
  currentUserGroup: Group | null
  // Group mgmt state
  selectedGroupDocId: string | null
  selectedGroup: Group | null
  selectedGroupMembers: GroupMember[]
  ownedGroups: Group[] | null
}

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  firebase = inject(FirebaseService)
  private router = inject(Router)
  private userService = inject(UserService)
  private defaultsService = inject(DefaultsService)
  private utilityService = inject(PortalUtilityService)
  // Use signals for state

  checkingGroupToJoin = signal<Group|null>(null)

  private state = signal<GroupsState>({
    currentUserGroup: null,
    selectedGroupDocId: null,
    selectedGroup: null,
    ownedGroups: null,
    selectedGroupMembers: [],
  })

  selectedGroupDocId = computed(() => this.state().selectedGroupDocId)
  selectedGroup = computed(() => this.state().selectedGroup)
  ownedGroups = computed(() => this.state().ownedGroups || [])
  groupsMap = computed(() => new Map<string, Group>(this.ownedGroups()?.map(g => [g.docId as string, g]) || []))
  selectedGroupMembers = computed(() => this.state().selectedGroupMembers || [])
  currentUserGroup = computed(() => this.state().currentUserGroup)

  // Use sources for events

  waitingForAuthentication$ = new BehaviorSubject<any>(null)
  selectedGroupDocId$ = new BehaviorSubject<string | null | undefined>(null)

  // User's groups
  private ownedGroups$ = this.userService.user$
    .pipe(
      filter(user => !!user),
      distinctUntilKeyChanged('docId'),
      switchMap(user => 
        {
          return user.isAdmin 
            ? this.getAllGroups()
            : this.getGroups(user as UserRecord)
        })
    )

  selectedGroup$ = this.selectedGroupDocId$
    .pipe(
      distinctUntilChanged(),
      switchMap(docId => docId ? this.getGroup(docId as string) : of(null)),
      shareReplay(1)
    )

  selectedGroupMembers$ = this.selectedGroup$
    .pipe(
      filter(group => !!group),
      distinctUntilKeyChanged('docId'),
      switchMap(group => this.getGroupMembers(group?.docId as string)),
      shareReplay(1))

    selectedGroupCosts$ = this.selectedGroup$
        .pipe(
            filter(group => !!group),
            distinctUntilKeyChanged('docId'),
            switchMap(group => this.getGroupCosts(group?.docId as string)),
            shareReplay(1))

  selectedGroupLedgerEntries$ = this.selectedGroup$
    .pipe(
      filter(group => !!group),
      switchMap(group => this.getGroupLedger(group?.docId as string)),
      shareReplay(1))

  selectedGroupInvoices$ = this.selectedGroup$
      .pipe(
        filter(group => !!group),
        distinctUntilKeyChanged('docId'),
        switchMap(group => this.getGroupInvoices(group?.docId as string)),
        shareReplay(1))     

  currentUserGroup$ = this.userService.user$
    .pipe(
      filter(user => !!user),
      distinctUntilKeyChanged("groupDocId"),
      switchMap(user => user.groupDocId ? this.getGroup(user.groupDocId as string) : of(null)),
      shareReplay(1)
    )

  constructor(
    @Inject('environment') public environment: Environment) { 
      combineLatest([ this.waitingForAuthentication$,  this.userService.user$ ])
        .pipe(
          filter(([ waiting, user ]) => !!waiting ),
          takeUntilDestroyed()
        )
        .subscribe(([ waiting, user ]) => {
          this.waitingForAuthentication$.next(null)
            this.joinGroup(user as UserRecord, waiting.group)
        })

        // Connect all observables to the state
        // See https://www.youtube.com/watch?v=R7-KdADEq0A
        connect(this.state)
          .with(this.selectedGroup$, (prev, selectedGroup) => ({ ...prev, selectedGroup }))
          .with(this.selectedGroupMembers$, (prev, selectedGroupMembers) => ({ ...prev, selectedGroupMembers }))
          .with(this.ownedGroups$, (prev, ownedGroups) => ({ ...prev, ownedGroups }))
          .with(this.currentUserGroup$, (prev, currentUserGroup) => ({ ...prev, currentUserGroup }))

        // effect(() => console.log('GroupsState', this.state()))
  }

    private getGroups(user: UserRecord) {
        return this.firebase.collection$(FirestoreCollectionTypes.GROUPS_COLLECTION, where('ownerUserId', '==', user.docId), orderBy('createdAt', 'desc'))
        .pipe(
            debounceTime(200),
            map(groups => GroupConverter.fromArray(groups)),
            tap(res => console.log(`getGroups retrieved ${res.length} groups for user ${user.docId}`)),
            // TODO: augment with member counts
            shareReplay(1)
        )
    }

    getAllGroups() {
        return this.firebase.collection$(FirestoreCollectionTypes.GROUPS_COLLECTION, orderBy('createdAt', 'desc'))
            .pipe(
                debounceTime(200),
                map(groups => GroupConverter.fromArray(groups)),
                tap(res => console.log(`getAllGroups retrieved ${res.length} groups`)),
                shareReplay(1)
            )
    }

    getGroup(docId: string) {
        return this.userService.getGroup(docId)
    }

  getGroupMembers(docId: string)  {
    return this.firebase.collection$(`${FirestoreCollectionTypes.GROUPS_COLLECTION}/${docId}/members`)
      .pipe(
        debounceTime(300),
        map(members => GroupMemberConverter.fromArray(members)),
        tap(res => console.log('getGroupMembers', `retrieved group members`, res)),
        shareReplay(1)
      )
  }

  getGroupCosts(docId: string)  {
    return this.firebase.collection$(`${FirestoreCollectionTypes.COSTS_COLLECTION}`, where('groupDocId', '==', docId), orderBy('loggedAt', 'desc'))
      .pipe(
        debounceTime(300),
        map(costs => CostRecordConverter.fromArray(costs)),
        tap(res => console.log('getGroupCosts', `retrieved group costs`, res)),
        shareReplay(1)
      )
  }

  getGroupLedger(docId: string)  {
    return this.firebase.collection$(this.getGroupLedgerPath(docId), orderBy('createdAt', 'desc'))
      .pipe(
        debounceTime(300),
        tap(res => console.log('getGroupLedger', `retrieved group ledger entries`, res)),
        shareReplay(1)
      )
  }

  getGroupInvoices(docId: string)  {
    return this.firebase.collection$(this.getGroupInvoicesPath(docId), orderBy('created', 'desc'))
      .pipe(
        debounceTime(300),
        tap(res => console.log('getGroupInvoices', `retrieved group invoices`, res)),
        shareReplay(1)
      )
  }

  hasOwnedGroups(user: UserRecord) {
    console.log('hasOwnedGroup user', user)
    console.log('ownedGroups', this.state().ownedGroups)
    return !!this.ownedGroups()?.find(g => g.ownerUserId === user?.docId)
  }

  async createGroup(groupName: string, user: UserRecord) {
    if (await this.checkGroupName(groupName)) {
      const now = new Date()
      await this.putGroup({} as Group, { 
          name: groupName, 
          ownerUserId: user.isAdmin ? null : user.docId, // Don't assign owner if created by admin
          createdAt: now,
          updatedAt: now
      })
    }
    else {
      await this.utilityService.presentToast(`Invalid group name: ${groupName}`)
    }
  }

    // Return true if group name is new for user
    private async checkGroupName(name: string) {
        if(!name || name.length < MIN_GROUP_NAME_LENGTH || name.length > MAX_GROUP_NAME_LENGTH) {
            console.error(`Group name must be between ${MIN_GROUP_NAME_LENGTH} and ${MAX_GROUP_NAME_LENGTH} characters`)
            return false
        }
        const groups = this.ownedGroups()
        return !groups?.find(g => g.name === name)
    }

    async doesGroupOwnerOwnAnotherGroup(ownerUserId: string, group: Group) {
        const allGroups = await firstValueFrom(this.getAllGroups())
        try {
            const owner = await firstValueFrom(this.userService.getUser(ownerUserId))
            return !owner.isAdmin && allGroups.find(g => g.ownerUserId === ownerUserId && g.docId !== group.docId)
        }
        catch (e) {
            console.warn('Failed to get user', ownerUserId)
            return false
        }
    }

  async putGroup(group: Group, params: any) {
    console.log('putGroup', group, params)
    const now = new Date()
    // create or update
    if (!group.docId) {
      try {
        group.docId = this.firebase.generateDocID()
      }
      catch (e) {
        console.error('Failed to generate docId in firebase, using timestamp', e)
        // Compute number of milliseconds since epoch
        group.docId = `${now.getTime()}`
      }
    }
    const path = `${FirestoreCollectionTypes.GROUPS_COLLECTION}/${group.docId}`
    // if there is no invitation link, generate one
    const data = !(group.invitationLink || params.invitationLink)
      ? { ...params, docId: group.docId, invitationLink: await this.generateInviteLink({ ...group, ...params}) }
      : { ...params, docId: group.docId }
    return await this.firebase.updateAt(path, { ...data, updatedAt: now }) 
  }

    async generateInviteLink(group: Group) {    
        //TODO - encode URL params ?
        return `${this.environment.portalHost}/group-invite/${group.docId}`
    }

    sendWelcomeToGroupEmail(group: Group, email: string) {
        // const subject = substituteArgs(this.defaultsService.getDefault('welcome.email.subject'))
        // const message = this.defaultsService.getDefault('welcome.email.message')
        // const html = substituteArgs(message, [email, group.name, this.environment.adminEmail, this.environment.adminEmail ])
        this.firebase.callCloudFunction("sendEmailAttachment", {
            provider: MailProviders.MAILGUN,
            to: email,
            subject: 'Welcome to fidocs group ' + group.name,
            // html,
            // text: html,
            template: 'welcome',
            variables: { first_name: email }
        })
        .subscribe(result => console.log(result))
    }

    sendInvitationEmail(group: Group, email: string) {
        this.firebase.callCloudFunction("sendEmailAttachment", {
            provider: MailProviders.MAILGUN,
            to: email,
            template: 'group-invite',
            variables: { email, group_owner: group.ownerUserId, group_name: group.name, link: group.invitationLink }
            // subject: 'Join fidocs group: ' + group.name,
            // text: 'Click this link to join the group: ' + group.invitationLink
        }).subscribe(result => console.log(result))
    }  

    sendPendingApprovalEmail(group: Group, pendingEmail: string) {
        this.firebase.callCloudFunction("sendEmailAttachment", {
            to: group.ownerUserId || this.environment.adminEmail,
            subject: `${pendingEmail} is waiting for approval to join group ${group.name}`,
            text: `\nYou are an owner of ${group.name}. Click this link to manage approvals: ${this.environment.portalHost}/group/${group.docId}?tab=members`
        }).subscribe(result => console.log(result))
    }  

    async checkJoinGroup(user: UserRecord, group: Group) {
        console.log('checkJoinGroup', user, group)
        this.checkingGroupToJoin.set(group)
        await this.joinGroup(user, group)
    }

  async joinGroup(user: UserRecord, group: Group) {
    if (user.groupDocId === group.docId) {
      await this.utilityService.presentToast(
        `You are already a member of group ${group.name}`,
        { duration: 6000 })
      this.routeHome()
    }
    else if (user.groupDocId && user.groupDocId !== group.docId) { // Handle switching groups
      const currentGroup = await firstValueFrom(this.getGroup(user.groupDocId as string))
      if (currentGroup) {
        let msg = this.defaultsService.getDefault('portal.group.leave.message') ||
          `You are currently a member of group $0. Are you sure you want to withdraw from that group and join $1 ?`
        msg = msg.replace(/\$\w+/, currentGroup?.name)
        msg = msg.replace(/\$\w+/, group.name)
        await this.utilityService.confirm({
          header: 'Join Group',
          message: msg,
          confirm: async () => {
            await this.deleteMember(user.docId as string, currentGroup)
            await this.addUserToNewGroup(user, group)
            this.routeHome()
          },
          cancel: () => { this.routeHome() }  
          })
      }
      else {
        // No need to delay if user is already in a group
        await this.addUserToNewGroup(user, group)
        this.routeHome()  
      }
    }
    else {
      // delay here to ensure new groupDocId is observed
      await this.addUserToNewGroup(user, group)
      await delay(2000)
      this.routeHome()
    }
    this.checkingGroupToJoin.set(null)
  }

  routeHome() {
    this.router.navigateByUrl('/home')
  }

  async addUserToNewGroup(user: UserRecord, group: Group) {
    const members = await firstValueFrom(this.getGroupMembers(group.docId as string))
    const member = members.find(m => m.userId.toLowerCase() === user.docId?.toLowerCase())
    const isPreApproved = member?.status === 'pre-approved'
    const isInvited = member?.status === 'invited'
    console.log('joinGroup', { user, group, isPreApproved })
    // Pending now only applies to prepaid groups 05/30/2024
    if (isPreApproved || isInvited ) {
      const message = `You are now a member of the group ${group.name}.`
      await this.userService.setGroupDocId(user.docId as string, group.docId as string)
      await this.putGroupMember(group, { userId: user.docId as string, status: 'active' } as GroupMember)
      await this.utilityService.presentToast(message, { duration: 4000 })
    }
    else {
      const message = `The administrator of group ${group.name} needs to approve your membership.`
      await this.putGroupMember(group, { userId: user.docId as string, status: 'pending' } as GroupMember)
      await this.utilityService.presentToast(message, { duration: 4000 })
      this.sendPendingApprovalEmail(group, user.docId as string)
    }
    this.sendWelcomeToGroupEmail(group, user.docId as string)
  }

  async addPreApprovedMembers(group: Group, emailStr: string, members: GroupMember[]) {
    const emails = splitTrim2(emailStr)
    const newEmails = emails.filter(e => !members.find(m => m.userId === e)).map(e => e.toLowerCase())
    for (const e of newEmails) {
      this.putGroupMember(group, { userId: e, status: 'pre-approved' } as GroupMember)
    }
  }

  async inviteMembers(group: Group, emailStr: string, members: GroupMember[]) {
    const emails = splitTrim2(emailStr)
    const newEmails = emails.filter(e => !members.find(m => m.userId === e)).map(e => e.toLowerCase())
    for (const email of newEmails) {
      await this.putGroupMember(group, { userId: email, status: 'invited' } as GroupMember)
      this.sendInvitationEmail(group, email)
    }
    
  }

  async putGroupMember(group: Group, member: GroupMember) {
    await this.firebase.updateAt(`${FirestoreCollectionTypes.GROUPS_COLLECTION}/${group.docId}/members/${member.userId}`, 
        { ...member, updatedAt: new Date() })
  }

  async approveGroupMember(userId: string, group: Group) {
    console.log('approveMember', userId, group)
    const now = new Date()
    await this.putGroupMember(group, { userId, status: 'active', approvedAt: now } as GroupMember)
    await this.userService.setGroupDocId(userId, group.docId as string)
  }

  canRemoveGroupMember(member: GroupMember) {
    return member.status !== 'active'
  }

  async removeGroupMember(member: GroupMember, group: Group) {
    await this.utilityService.confirm({
      header: 'Remove Member',
      message: `Are you sure you want to remove ${member.userId} from the group?`,
      confirm: () => {
        this.leaveGroup(member.userId, group)
      }
    })
  }

  async removeAllGroupMembers(group: Group, members: GroupMember[]) {
    for (const m of members)
      await this.leaveGroup(m.userId, group)
  }

  async removeGroupUser(userId: string, group: Group) {
    await this.utilityService.confirm({
      header: 'Leave Group',
      message: `Are you sure you want to leave ${group.name}?`,
      confirm: () => {
        this.leaveGroup(userId, group)
      }
    })
  }

  // Remove the member from the group and update user
  async leaveGroup(userId: string, group: Group) {
    console.log('leaveGroup', userId, group)
    // Make sure user exists
    const user = await firstValueFrom(this.firebase.doc$(this.userService.getUserPath(userId)))
    if (user?.docId) {
      await this.userService.setGroupDocId(userId, null)
    }
    await this.deleteMember(userId, group)
  }

  async deleteMember(userId: string, group: Group) {
    await this.firebase.delete(`${FirestoreCollectionTypes.GROUPS_COLLECTION}/${group.docId}/members/${userId}`)
  }

  async handleGroupPayment(user: UserRecord, seedType: string) {
    const group = this.currentUserGroup() as Group
    console.warn('Not yet implemented')
    return true
  }

  async addGroupLedgerEntry(entry: GroupLedgerEntry, group: Group) {
    const path = this.getGroupLedgerPath(group.docId as string)
    return await this.firebase.updateAt(path, entry)
  }

  getGroupLedgerPath(groupId: string) {
    return `groups/${groupId}/${GROUP_LEDGER_COLLECTION_NAME}`
  }

  getGroupInvoicesPath(groupId: string) {
    return `groups/${groupId}/${GROUP_INVOICES_COLLECTION_NAME}`
  }


  isOwnerOfCurrentGroup(userId: string) {
    const group = this.currentUserGroup()
    return group?.ownerUserId === userId
  }

  deleteGroup(group: Group) {
    this.firebase.delete(`${FirestoreCollectionTypes.GROUPS_COLLECTION}/${group.docId}`)
  }
}