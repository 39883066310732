export interface AzureServerConfig  {
    env: string,
    endpoint: string,
    key: string,
    models: string[],
    defaultModel?: string
}

export const AZURE_OPENAI_SERVERS: AzureServerConfig[] = [
    { env: 'prod', endpoint: 'https://fidocseasthub8701665152.openai.azure.com/', key: '6295dfd5e7b3442f8f748795520d7784', 
        models: [ 'gpt-4o', 'gpt-4o-2024-11-20' ], defaultModel: 'gpt-4o'},
    { env: 'dev', endpoint: 'https://fidocswesthub4608667398.openai.azure.com/', key: 'e37dc6c3a5124f88a9b7d905d2b2d0e5', 
        models: [ 'gpt-4o', 'gpt-4o-2024-11-20' ], defaultModel: 'gpt-4o'}   
]

export const AZURE_OPENAI_DEPLOYMENTS = [
    { 
        env: 'prod', 
        endpoint: 'https://fidocseasthub8701665152.openai.azure.com/',
        key: '6295dfd5e7b3442f8f748795520d7784', 
        model: 'gpt-4o'
    },
    {
        env: 'dev',
        endpoint: 'https://adam-m5ppc6ay-eastus2.services.ai.azure.com/',
        key: '66rmZXpK3H7aHSlSQoM4Y23L5fRKZlx8ubnD1dXt6kH4UYE3n4TVJQQJ99BAACHYHv6XJ3w3AAAAACOGvvgb',
        model: 'gpt-4o-2024-11-20'
    },
    { 
        env: 'dev', 
        endpoint: 'https://fidocswesthub4608667398.openai.azure.com/',
        key: 'e37dc6c3a5124f88a9b7d905d2b2d0e5', 
        model: 'gpt-4o'
    }
]

export const AZURE_DOCINTEL_SERVERS = [
    {
        env: 'dev',
        endpoint: 'https://fidocs-west-docintel.cognitiveservices.azure.com/',
        key1: 'b9a2d47d62fd4c8c8566cde2040d0cad',
        key2: 'd8f5e74d03c4450297988fa272e2aa6d',
        region: 'westus'
    },
    {
        env: 'dev',
        endpoint: 'https://fidocs-east-docintel.cognitiveservices.azure.com/',
        key1: '1e64e70122114fc3bcebe33b65cddb29',
        key2: '5a0b35ea05474bf7b7b688c02177a132',
        region: 'eastus'
    },
    {
        env: 'dev',
        endpoint: 'https://fidocs-northcentral-docintel.cognitiveservices.azure.com/',
        key1: '9a6af82ea98a432daf55db938f8ec16c',
        key2: 'f60c9e4931934975a5480c210e9e6554',
        region: 'northcentralus'
    }
]

export function getAzureOpenAIServer(env: "dev" | "prod"): AzureServerConfig {
    //console.log("Azure End Point lookup env", env)
    if (['dev', 'prod'].includes(env))
        return AZURE_OPENAI_SERVERS.find( server => server.env === env )
    else
        throw new Error('Internal Error: Invalid environment specified in Azure server lookup')    
}
