@if (userService.user$ | async; as user) {
    <div class="sm:flex sm:justify-between sm:items-center sm:gap-4">
        <div class="text-xl sm:text-2xl p-4 py-6">
            @if (groupService.ownedGroups().length === 0) {
                <lib-default-message key="groups.title.message"/>           
            }

        </div>
        <button class="mx-4 bg-fidocs-green hover:bg-slate-400 text-sm text-white font-bold py-2 px-4 rounded" (click)="createGroup(user)">
            Create Group
        </button>
    </div>
    <div class="flex flex-col p-4 text-sm w-full">
        <table class="my-8 w-full border-separate border-0 border-slate-400 text-sm">
            <thead>
                <tr class="px-2 text-center">
                    <th>Group</th>
                    <th>Owner</th>
                    <th>Link</th>
                    <th>Balance</th>
                    <th class="w-44">Created</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
            @for (group of groupService.ownedGroups(); track group.docId) {
                <tr class="even:bg-slate-100 odd:white text-center h-12">
                    <td><a (click)="groupService.selectedGroupDocId$.next(group.docId)" class="text-blue-600 underline cursor-pointer">{{ group.name }}</a></td>
                    <td>{{ group.ownerUserId }}</td>
                    <td><a [href]="group.invitationLink" class="text-blue-600 underline">{{ group.invitationLink }}</a></td>
                    <td>{{ group.pageBalance }}</td>
                    <td>{{ group.createdAt | date: 'yyyy-MM-dd hh:mma' }}</td>
                    <td class="w-28">
                        <button mat-icon-button (click)="deleteGroup(group)">
                            <span class="material-symbols-outlined">
                                delete
                            </span>
                        </button>
                </tr>
            }
            </tbody>
        </table>
    </div>
}
