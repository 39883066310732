import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirebaseService } from '@fidoc/util';
import { CostRecordConverter, FirestoreCollectionTypes, stringifyKeysInOrder } from '@fidoc/shared';
import { limit, orderBy } from '@angular/fire/firestore';
import { debounceTime, firstValueFrom, shareReplay } from 'rxjs';
import { downloadCSV } from '@cheaseed/node-utils';
import { FileflowService } from '@fidoc/fileflow';

@Component({
  selector: 'lib-cost-table',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cost-table.component.html',
  styleUrl: './cost-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CostTableComponent {
    firebase = inject(FirebaseService)
    fileflowService = inject(FileflowService)

    costs$ = this.firebase.collectionWithConverter$(
        FirestoreCollectionTypes.COSTS_COLLECTION, 
        CostRecordConverter,
        orderBy('loggedAt', 'desc'),
        limit(250))    
    .pipe(
      debounceTime(200),
      shareReplay(1)
    )

    async exportCosts() {
        const costs = await firstValueFrom(this.fileflowService.allCosts$)
        const headers = [
            'user',
            'groupDocId',
            'loggedAt',
            'fileDocId',
            'fileName',
            'fileSize',
            'numPages',
            'stepName',
            'promptDocId',
            'credits',
            'cost',
            'azureModelUsed',
            'azureTokensUsed',
            'azureCost',
            'elapsedMsec'
        ]
        downloadCSV(costs, headers, 'all-costs.csv')
        // const json = stringifyKeysInOrder(costs);
        // const blob = new Blob([json], {type: 'application/json'});
        // const url = URL.createObjectURL(blob);
        // const link = document.createElement('a');
        // link.href = url;
        // link.download = "all-costs.json"
        // link.click();
        // setTimeout(() => URL.revokeObjectURL(url), 60) // For Firefox it is necessary to delay revoking the ObjectURL  
    }

}
