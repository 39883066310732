@if (userService.user(); as user) {
    <div class="p-8 grid grid-cols-3 gap-4 items-center bg-slate-100">
        <div class="font-bold">User</div>
        <div>{{ user.docId }}</div>
        <div></div>
        <div class="font-bold">Username</div>
        <div>{{ user.name }}</div>
        <div>
            <button *ngIf="!user.name" mat-raised-button (click)="editUserName()" class="w-8 justify-self-center">
                <span class="material-symbols-outlined">edit</span>
            </button>
        </div>
        <div class="font-bold">Created</div>
        <div>{{ user.createdAt | date: 'yyyy-MM-dd hh:mma' }}</div>
        <div></div>
        <div class="font-bold">Default Pipeline</div>
        <div>{{ describePipeline(user.defaultPipeline) }}</div>
        <div>
            <button *ngIf="userService.user()?.isAdmin" mat-raised-button (click)="editDefaultPipeline.set(true)" class="w-8 justify-self-center">
                <span class="material-symbols-outlined">edit</span>
            </button>
        </div>
        @if (user.groupDocId) {
            <div class="font-bold">Member of Group</div>
            <div>{{ groupService.currentUserGroup()?.name }}</div>
            <div>
                @if (user.docId === groupService.currentUserGroup()?.ownerUserId) {
                    <button mat-raised-button (click)="groupService.selectedGroupDocId$.next(user.groupDocId)" class="max-w-48 justify-self-center">
                        Manage Group
                    </button>
                }
                @else {
                    <div>
                        Group Owner: {{ groupService.currentUserGroup()?.ownerUserId || 'None' }}
                    </div>
                }
            </div>
        }
        @if (!user.groupDocId && user.subscriptionInfo; as info) {
            <div class="font-bold">Subscription</div>
            <div class="text-xs">
                @if (stripeService.getProduct(info.productId!); as product) {
                    @let sub = subscription();
                    @let discount = sub?.discount?.coupon.percent_off || 100;
                    <table class="border-collapse">
                        <tbody>
                            <tr>                                
                                <td colspan="2" class="text-left font-bold text-sm text-blue-500">{{ product.name }}</td>
                            </tr>
                            <tr>
                                <th class="text-left pr-2">Status</th>
                                <td>{{ getStatusDescription(info.status) }}</td>
                            </tr>
                            @if (discount < 100) {
                                <tr>
                                    <th class="text-left pr-2">Discount</th>
                                    <td>{{ discount / 100 | percent }}</td>
                                </tr>                                
                            }
                            @if (info.subscriptionEndDate) {
                                @if(info.cancelled) {
                                    <tr>
                                        <th class="text-left pr-2">Renewal Date</th>
                                        <td>None (Subscription was cancelled)</td>
                                    </tr>
                                    <tr>
                                        <th class="text-left pr-2">Subscription Valid Till</th>
                                        <td>{{ info.subscriptionEndDate | date: 'yyyy-MM-dd' }}</td>
                                    </tr>
                                }
                                @else {
                                    <tr>
                                        <th class="text-left pr-2">Renewal Date</th>
                                        <td>{{ info.subscriptionEndDate | date: 'yyyy-MM-dd' }}</td>
                                    </tr>
                                }
                                @if (user.pageBalance >= 0) {
                                    @if (info.status === 'trialing') {
                                        <tr>
                                            <th class="text-left pr-2">Page Quota</th>
                                            <td>{{ starting_trial_page_balance }}</td>
                                        </tr>
                                        <tr>
                                            <th class="text-left pr-2">Pages Consumed</th>
                                            <td>{{ starting_trial_page_balance - user.pageBalance }}</td>
                                        </tr>
                                    }
                                    @else {
                                        <tr>
                                            <th class="text-left pr-2">Page Quota</th>
                                            <td>{{ info.pagesAllocated }}</td>
                                        </tr>
                                        <tr>
                                            <th class="text-left pr-2">Pages Consumed</th>
                                            <td>{{ (info.pagesAllocated || 0) - user.pageBalance }}</td>
                                        </tr>                                    
                                    }
                                }
                                @else {
                                    <!-- Show Page Overage Data -->
                                    <tr>
                                        <th class="text-left pr-2">Page Overage</th>
                                        <td>{{ 0 - user.pageBalance }}</td>
                                    </tr>
                                    <tr>
                                        <th class="text-left pr-2">Overage Cost</th>
                                        <td>{{ (0 - user.pageBalance) * unit_price() | currency: 'USD' }}</td>
                                    </tr>
                                }
                            }
                            @else if (info.trialEndDate) {
                                <tr>
                                    <th class="text-left pr-2">Trial Ends</th>
                                    <td>{{ info.trialEndDate | date: 'yyyy-MM-dd' }}</td>                            
                                </tr>                            
                            }
                        </tbody>
                    </table>
                }
            </div>
            <button mat-raised-button 
                class="max-w-48" 
                (click)="userService.manageStripeSubscription(user)">
                Manage Subscription
            </button>
        }
        @else if (user.enterpriseTrial) {
            <div class="font-bold">Enterprise Trial</div>
            <div class="text-xs">
                <table class="border-collapse">
                    <tbody>
                        <tr>
                            <th class="text-left pr-2">Trial End Date</th>
                            <td>{{ user.enterpriseTrial.evalEndDate }}</td>
                        </tr>
                        <tr>
                            <th class="text-left pr-2">Page Quota</th>
                            <td>{{ user.enterpriseTrial.trialPages }}</td>
                        </tr>
                        <tr>
                            <th class="text-left pr-2">Pages Remaining</th>
                            <td>{{ user.pageBalance }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        }
    </div>    

    <ion-modal #defaultPipeline
    [isOpen]="editDefaultPipeline()" 
    canDismiss="true"
    (didDismiss)="editDefaultPipeline.set(false)"
    style="--height: 80vh; --width: 85%; --min-width: 300px; --max-width: 700px">
    <ng-template>
        <ion-content style="height: 80vh">
            <lib-pipeline-selector
                message1="Select your default pipeline."
                [selection]="user.defaultPipeline"
                (submit)="setDefaultPipeline($event)" 
                (dismiss)="defaultPipeline.dismiss()" />
        </ion-content>
    </ng-template>
    </ion-modal>
}
@else {
    <mat-spinner class="fidocs-spinner"></mat-spinner>
}