import { ChangeDetectionStrategy, Component, Inject, model, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { DefaultMessageComponent, Environment } from '@fidoc/util';

@Component({
  selector: 'lib-accept-terms',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatCheckboxModule,
    MatButtonModule,
    DefaultMessageComponent
  ],
  templateUrl: './accept-terms.component.html',
  styleUrl: './accept-terms.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcceptTermsComponent {
  acceptPrivacy = model(false)
  acceptTerms = model(false)
  // acceptBeta = model(false)
  accepted = output<boolean>()

  constructor(@Inject('environment') public environment: Environment) {}
}
