<div class="text-left">
    <lib-default-message key="accept.terms.markdown" [markdown]="true"/>
    <div class="pl-16 py-8 flex flex-col items-start justify-center gap-2">
        <mat-checkbox [(ngModel)]="acceptTerms">
            I have reviewed and accept the <a [href]="environment.webHost + '/tos'" class="underline" target="_blank">Terms of Service</a>
        </mat-checkbox>
        <mat-checkbox [(ngModel)]="acceptPrivacy">
            I have reviewed and accept the <a [href]="environment.webHost + '/privacy'" class="underline" target="_blank">Privacy Policy</a>
        </mat-checkbox>
        <!-- <mat-checkbox [(ngModel)]="acceptBeta">
            I have reviewed and accept the <a href="/beta-agreement" class="underline" target="_blank">Beta Agreement</a>
        </mat-checkbox> -->
        <button mat-raised-button 
            class="mt-8"
            [disabled]="!acceptTerms() || !acceptPrivacy()"
            (click)="accepted.emit(true)">
            Submit
        </button>
    </div>
</div>