import { ChangeDetectionStrategy, Component, Inject, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileflowService, FileSummaryComponent, ToolsService } from '@fidoc/fileflow';
import { marked } from 'marked'
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { BehaviorSubject, combineLatestWith, debounceTime, map, shareReplay } from 'rxjs';
import { DefaultRecord, DefaultsService, FirebaseService, UserService } from '@fidoc/util';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { IonModal, IonContent, IonSearchbar } from "@ionic/angular/standalone";
import { PortalUtilityService } from '@cheaseed/portal/util';
import { JsonFormComponent } from '@cheaseed/shared/ui';
import { JsonFormData  } from '@cheaseed/node-utils';
import { FlowFile, flowFileConverter, FlowTool, UserRecord, FirestoreCollectionTypes } from '@fidoc/shared';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DomainComponent } from '../domain/domain.component';
import { orderBy } from '@angular/fire/firestore';
import { GroupsComponent, GroupService } from '@fidoc/groups';
import { UserTableComponent } from '../user-table/user-table.component';
import { CostTableComponent } from '../cost-table/cost-table.component';

@Component({
  selector: 'lib-admin',
  standalone: true,
  imports: [
    IonSearchbar,
    IonContent,
    IonModal,
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    JsonFormComponent,
    FileSummaryComponent,
    DomainComponent,
    GroupsComponent,
    UserTableComponent,
    CostTableComponent
],
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminComponent {
  toolsService = inject(ToolsService)
  flowService = inject(FileflowService)
  userService = inject(UserService)
  defaultsService = inject(DefaultsService)
  utilityService = inject(PortalUtilityService)
  firebase = inject(FirebaseService)
  groupService = inject(GroupService)
  
  private route = inject(ActivatedRoute)
  private router = inject(Router)

  tab$ = new BehaviorSubject('')
  tabIndex$ = new BehaviorSubject(0)

  editDefault$ = new BehaviorSubject<DefaultRecord | null>(null)
  provisionUser$ = new BehaviorSubject(false)

  tools$ = this.toolsService.tools$
    .pipe(
      map(toolDocs => {
        return this.toolsService.getTools()
          .map(tool => {
            const toolDoc = toolDocs.find(doc => doc.name === tool.name)
            const instructions = toolDoc?.instructions || tool.instructions
            const markdownInstructions = instructions ? this.flowService.renderMarkdown(instructions) : undefined
            return {
              ...tool,
              instructions,
              markdownInstructions
            }
          })
      })
    )

  searchFilter$ = new BehaviorSubject<string|null|undefined>('') 
  userSearchFilter$ = new BehaviorSubject<string|null|undefined>('') 

  recentUsers$ = this.userService.recentUsers$
    .pipe(
      debounceTime(300),
      combineLatestWith(this.userSearchFilter$),
      map(([users, searchTerm]) => users.filter(user => (!searchTerm || ('' + user.name + user.docId).toLowerCase().indexOf(searchTerm.toLowerCase()) > -1))),
      shareReplay(1),
    )

  recentFiles$ = this.firebase.collectionGroupWithConverter$<FlowFile>(
    FirestoreCollectionTypes.FILEFLOW_COLLECTION, 
    flowFileConverter, 
    orderBy('createdAt', 'desc'))
    .pipe(
      debounceTime(300),
      shareReplay(1),
      combineLatestWith(this.searchFilter$),
      map(([files, searchTerm]) => files.filter(file => (!searchTerm || ('' + file.fileName + file.userDocId).toLowerCase().indexOf(searchTerm.toLowerCase()) > -1))),
      // sort all files by createdAt descending
      // map(files => files.toSorted((a, b) => a.createdAt > b.createdAt ? -1 : 1)),
      // tap(files => console.log('files', files.map(f => ({ docId: f.docId, createdAt: f.createdAt }))))
    )
  
    provisionUserFormData: JsonFormData = {
      controls: [ 
        {
          name: 'email',
          type: 'email',
          label: 'Email',
          errorText: "Must be a valid email address",
          validators: {
            required: true,
            email: true
          },
        },
        {
          name: 'user',
          type: 'text',
          validators: {
            required: true,
            minLength: 3
          },
          label: 'User Name',
        },
        {
          name: 'evalEndDate',
          type: 'date',
          validators: {
            required: true,
          },
          label: 'Evaluation End Date'
        },
        {
          name: 'pages',
          type: 'number',
          validators: {
            required: true,
            pattern: '^[0-9]+$'
          },
          label: 'Pages'
        }
      ]
    };

  billOverageFormData: JsonFormData = {
    controls: [
      {
        name: 'overageStartDate',
        type: 'date',
        validators: {
          required: true,
        },
        label: 'From'
      },
      {
        name: 'overageEndDate',
        type: 'date',
        validators: {
          required: true,
        },
        label: 'Till'
      }
    ]
  } 
  constructor(
    @Inject('environment') private environment: any
  ) {
    
    marked.setOptions({
      pedantic: false,
      gfm: true,
      breaks: false
    })

    // Ensure user has admin role
    this.userService.user$
      .pipe(
        takeUntilDestroyed())
      .subscribe(user => {
        const isAdmin = !!user?.isAdmin
        if (!isAdmin) {
          console.log('non-admin user, redirecting to home')
          this.router.navigate(['home'])
        }
      })      

    this.route.queryParamMap
      .pipe(
        takeUntilDestroyed())
      .subscribe(params => {
        const userId = params.get('approve')
        if (userId) {
          console.log('approve', userId)
          this.tabIndex$.next(0)
        }
      })
  }

  async editInstructions(tool: FlowTool) {
    const params = this.flowService.getToolParameters(tool)
    params.controls = params.controls.filter(param => param.name === 'instructions' )
    console.log('params', params)
    this.flowService.showToolParameters$.next({ tool, file: null, params })

    // await this.utilityService.prompt({
    //   header: `Edit Instructions for ${tool.name}`,
    //   inputType: 'textarea',
    //   inputValue: tool.instructions,
    //   inputCssClass: 'instructions-prompt',
    //   confirm: (result: any) => {
    //     const instructions = result.value 
    //     tool.instructions = instructions // record current instructions on tool
    //     this.flowService.updateTool(tool, { instructions })
    //   }
    // })
  }

  tabChanged(ev: any) {
    // console.log('tab changed', ev)
    this.tabIndex$.next(ev.index)
    this.tab$.next(ev.tab.textLabel.toLowerCase())
  }

  editDefault(doc: DefaultRecord) {
    this.editDefault$.next(doc)
  }

  addDefault() {
    this.editDefault$.next({ key: '', value: '', updatedAt: new Date() })
  }

  async deleteDefault(doc: DefaultRecord) {
    await this.utilityService.confirm({
      header: `Delete ${doc.key}`,
      message: `Are you sure you want to delete default for ${doc.key} ?`,
      confirm: () => {
        this.defaultsService.deleteDefault(doc)
      },
    });
  }

  submitDefault(controls: any, doc: DefaultRecord) {
    if (controls.key && controls.value) {
      const doc: DefaultRecord = {
        key: controls.key,
        value: controls.value,
        updatedAt: new Date()
      }
      this.defaultsService.updateDefault(doc, { key: controls.key, value: controls.value})
    }
  }

  prepareDefaultForm(doc: DefaultRecord) {
    const cssClass = 'white text-base text-black'
    const formData: JsonFormData = {
      submitAlwaysEnabled: true,
      controls: [ 
        { 
          name: 'key', 
          label: 'Key',
          type: 'textarea',
          cssClass,
          value: doc.key
        },
        { 
          name: 'value', 
          label: 'Value',
          type: 'textarea',
          cssClass,
          value: doc.value
        }
      ]
    }
    return formData
  }

  async toggleRole(user: UserRecord) {
    await this.userService.updateUser(user, { currentRole: user.currentRole === 'user' ? 'admin' : 'user' })
  }

  onFormRejectSubmit(data: any) {
    // console.log('Form reject submit', data)
    this.utilityService.presentToast('Form rejected' , { position: 'bottom' })
  }
}
