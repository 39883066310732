<div class="p-4 py-0">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms" [selectedIndex]="tabIndex$ | async" (selectedTabChange)="tabChanged($event)">
        <mat-tab label="Users" value="users">
            <ng-template matTabContent>
                <lib-user-table/>
            </ng-template>
        </mat-tab>
        <mat-tab label="Files" value="files">
            <ng-template matTabContent>
                <ng-container *ngIf="recentFiles$ | async as files; else loading">
                    <div class="p-4">
                        <ion-searchbar
                          [debounce]="500"
                          show-clear-button="always"
                          [value]="searchFilter$ | async"
                          (ionInput)="searchFilter$.next($event.target.value)">
                        </ion-searchbar>
                    </div>
                    @for (file of files; track file.docId) {
                        <div class="p-2 w-full even:bg-slate-100 odd:white">
                            <lib-file-summary 
                                [file]="file" 
                                [adminView]="true"
                                class="w-full"/>
                          </div>
                
                    }
                </ng-container>
            </ng-template>
        </mat-tab>
        <mat-tab label="Tools" value="tools">
            <div class="flex flex-col items-center justify-between w-full px-4">
                @for (tool of tools$ | async; track tool.name) {
                    @if (tool.instructions) {
                        <div class="flex flex-col items-start justify-center w-full py-3">
                            <div class="text-blue-400 text-2xl font-bold py-2">{{tool.name}}</div>
                            <div class="border p-2" [innerHtml]="tool.markdownInstructions"></div>                        
                            <button mat-raised-button class="my-2" (click)="editInstructions(tool)">
                                Edit
                            </button>
                        </div>
                    }
                }
            </div>        
        </mat-tab>
        <mat-tab label="Pipelines" value="pipelines"></mat-tab>
        <mat-tab label="Defaults" value="defaults">
            <div class="w-full flex flex-col">
                <button class="self-end mx-4 bg-fidocs-green hover:bg-slate-400 text-sm text-white font-bold py-2 px-4 rounded" (click)="addDefault()">
                    Add Default
                </button>
                <table class="my-8 w-full border-separate border-0 border-slate-400 text-sm">
                    <thead>
                        <tr class="px-2 text-center">
                            <th>Key</th>
                            <th>Value</th>
                            <th class="w-40">Last Updated</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                    @for (doc of defaultsService.allDefaults$ | async; track doc.docId) {
                        <tr class="even:bg-slate-100 odd:white text-center">
                            <td class="p-2 text-left">{{doc.key}}</td>
                            <td>{{doc.value | json}}</td>
                            <td>{{doc.updatedAt | date: 'yyyy-MM-dd hh:mma' }}</td>
                            <td class="w-28">
                                <button mat-icon-button (click)="editDefault(doc)">
                                    <span class="material-symbols-outlined">
                                        edit
                                    </span>                            
                                </button>
                                <button mat-icon-button (click)="deleteDefault(doc)">
                                    <span class="material-symbols-outlined">
                                        delete
                                    </span>                            
                                </button>
                            </td>
                        </tr>
                    }
                    </tbody>
                </table>
            </div>
        </mat-tab>
        <mat-tab label="Domains" value="domains">
            <lib-domain></lib-domain>
        </mat-tab>
        <mat-tab label="Groups" value="groups">
            <lib-groups></lib-groups>
        </mat-tab>
        <mat-tab label="Costs" value="costs">
            <ng-template matTabContent>
                <lib-cost-table/>
            </ng-template>
        </mat-tab>
        <mat-tab label="Actions" value="actions">
            <ng-template matTabContent>
                <div class="flex flex-col items-start p-8 gap-4">
                @if (userService.user(); as user) {
                    <button 
                        class="mx-4 bg-fidocs-green hover:bg-slate-400 text-sm text-white font-bold py-2 px-4 rounded" 
                        (click)="userService.setGroupDocId(user.docId, null)">
                        Remove groupDocId from {{ user.docId }}
                    </button>
                    <button class="mx-4 bg-fidocs-green hover:bg-slate-400 text-sm text-white font-bold py-2 px-4 rounded" (click)="toggleRole(user)">
                        Toggle Current Role to {{ user.currentRole === 'user' ? 'Admin' : 'User'}}
                    </button>
                    <button class="mx-4 bg-fidocs-green hover:bg-slate-400 text-sm text-white font-bold py-2 px-4 rounded" (click)="provisionUser$.next(true)">
                        Provision New Enterprise Trial User
                    </button>
                }
                </div>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>

<ion-modal #defaultsEditModal
  *ngIf="editDefault$ | async as doc"
  [isOpen]="!!doc" 
  canDismiss="true"
  (willDismiss)="utilityService.willDismiss()"
  (willPresent)="utilityService.willPresent()"
  (didDismiss)="editDefault$.next(null)"
  style="--height: 80vh; --width: 85%; --min-width: 300px; --max-width: 700px">
  <ng-template>
    <ion-content style="height: 80vh">
      <div class="absolute top-2 right-2 text-right cursor-pointer hover:bg-slate-200" (click)="defaultsEditModal.dismiss()">
        <span class="material-symbols-outlined text-xl">close</span>
      </div>
      <div class="p-8">
        <h1 class="pb-8 text-xl text-center font-bold w-full">{{ doc.key || 'Add Default' }}</h1>
        <cheaseed-json-form
          [formData]="prepareDefaultForm(doc)" 
          (formSubmit)="submitDefault($event, doc); defaultsEditModal.dismiss()"/>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>

<ion-modal #provisionUserModal
  [isOpen]="provisionUser$ | async" 
  canDismiss="true"
  (willDismiss)="utilityService.willDismiss()"
  (willPresent)="utilityService.willPresent()"
  (didDismiss)="provisionUser$.next(false)"
  style="--height: 50vh; --width: 85%; --min-width: 300px; --max-width: 700px">
  <ng-template>
    <ion-content style="height: 50vh">
      <div class="absolute top-2 right-2 text-right cursor-pointer hover:bg-slate-200" (click)="provisionUserModal.dismiss()">
        <span class="material-symbols-outlined text-xl">close</span>
      </div>
      <div class="p-8">
        <h1 class="pb-8 text-xl text-center font-bold w-full">Provision Enterprise Trial User</h1>
        <cheaseed-json-form
          [formData]="provisionUserFormData" 
          (formRejectSubmit)="onFormRejectSubmit($event)"
          (formSubmit)="userService.confirmEnterpiseTrialUserCreation($event); provisionUserModal.dismiss()"/>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>

<ng-template #loading>
    <mat-spinner class="fidoc-mat-spinner"></mat-spinner>
</ng-template>