import { FindOptions, NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer';

export async function findPdfText(
    pdfViewerService: NgxExtendedPdfViewerService, 
    searchText: string, 
    pageNum: number) {

    const findOptions: FindOptions = {    
        highlightAll: true,
        matchCase: true,
        wholeWords: false,
        matchDiacritics: false,
        findMultiple: false,
        regexp: false,
        dontScrollIntoView: false,
        useSecondaryFindcontroller: true,
    };
        
    const pagesWithResult: number[] = [];
    const numberOfResultsPromises = pdfViewerService.find(searchText, findOptions);
    if (!numberOfResultsPromises) {
        return;
    }

    const promises = numberOfResultsPromises.map((promise, index) => promise.then((numberOfResultsPerPage) => ({ index, numberOfResultsPerPage })));
    while (promises.length) {
        const { index, numberOfResultsPerPage } = await Promise.race(promises);
        // console.log('find result', index, numberOfResultsPerPage);
        if (numberOfResultsPerPage > 0 && !pagesWithResult.includes(index)) {
            pagesWithResult.push(index)
        }
        const promiseIndex = promises.findIndex((p) => p.then((result) => result.index === index));
        promises.splice(promiseIndex, 1);
    }
}
