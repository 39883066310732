import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomainService } from '@fidoc/util';
import { FlowDomain, FlowSchemaType, stringifyKeysInOrder } from '@fidoc/shared';
import { BehaviorSubject, distinctUntilChanged, filter, map } from 'rxjs';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FileflowService } from '@fidoc/fileflow';
import { PortalUtilityService } from '@cheaseed/portal/util';

@Component({
  selector: 'lib-domain',
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule
  ],
  templateUrl: './domain.component.html',
  styleUrl: './domain.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DomainComponent {

  fileflowService = inject(FileflowService)
  domainService = inject(DomainService)
  utilityService = inject(PortalUtilityService)
  domains$ = this.domainService.allDomains$
  selectedDomain$ = new BehaviorSubject<FlowDomain | null>(null)

  domainClasses$ = this.selectedDomain$
    .pipe(
      distinctUntilChanged(),
      filter(domain => !!domain),
      map(domain => {
        const classes = domain.classes.sort((a, b) => a.className < b.className ? -1 : 1)
        const map = new Map<string, FlowSchemaType>(classes.map(c => [c.className, c]))
        return { classes, map }
      }),
      map(data => data.classes.map(c => {
        const useClass = data.map.get(c.useSchemaOfClass)
        return c.useSchemaOfClass
          ? { ...c, schemaName: useClass?.schemaName, schemaId: useClass?.schemaId }
          : c
      }))
  )

  viewSchema(domain: FlowDomain, doc: FlowSchemaType) {
    const schema = doc.jsonSchema
    if (schema) {
      this.fileflowService.showInspector$.next({ 
        title: `${doc.className} Schema`,
        message: stringifyKeysInOrder(schema)
      })
    }
  }

  viewSchemaInstructions(domain: FlowDomain, doc: FlowSchemaType) {
    const cls = (doc.useSchemaOfClass 
      ? domain.classes.find(c => c.className === doc.useSchemaOfClass)
      : doc) as FlowSchemaType
    this.fileflowService.showInspector$.next({ 
      title: `${doc.className} Schema Instructions`,
      message: cls.instructions as string
    })
  }

  regenerateSchema(domain: FlowDomain, doc: FlowSchemaType) {
    // Dispatch to Github Action to run regenerate script
    this.utilityService.notify({ header: 'Coming Soon' })
  }



}
